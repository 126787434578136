import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { Button, Dropdown } from "react-bootstrap";
import { BsFilterCircle } from "react-icons/bs";
import styled, { keyframes } from "styled-components";
import { HiOutlineSearch } from "react-icons/hi";

const FtsFilterDropdown = ({ headerTitle, applyFtsFilters, ftsFilterKey }) => {
  const [inputText, setInputText] = useState("");

  const dropdownToggleRef = useRef(null);

  const handleOnChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSubmitClick = () => {
    applyFtsFilters(inputText, ftsFilterKey);
    setInputText("");
    dropdownToggleRef.current?.click();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputText != "") {
      handleSubmitClick();
    }
  };

  const theme = useSelector((state: RootState) => state.theme.mode);

  return (
    <DropdownWrapper>
      <Dropdown as={"span"} className="fts-filter-dropdown">
        <Dropdown.Toggle
          as="span"
          ref={dropdownToggleRef}
          style={{ cursor: "pointer" }}>
          {headerTitle} <BsFilterCircle style={{ marginLeft: "5px" }} />
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            minWidth: "9.6rem",
          }}>
          <FilterContainer
            style={{ background: theme === "light" ? "#f3f3f3" : "#1f1f1f" }}>
            <input
              type="text"
              style={{
                border: "none",
                background: theme === "light" ? "#f3f3f3" : "#1f1f1f",
                color: theme === "dark" ? "#f3f3f3" : "#1f1f1f",
                borderColor: theme === "light" ? "#f3f3f3" : "#1f1f1f",
                outline: "none",
              }}
              onChange={handleOnChange}
              size="13"
              value={inputText}
              placeholder="Search..."
              onKeyDown={handleKeyDown}
            />
            <Button
              disabled={!inputText}
              onClick={handleSubmitClick}
              id="searchButton"
              style={{
                background: "transparent",
                cursor: "pointer",
                borderColor: "transparent",
              }}>
              <HiOutlineSearch
                style={{ color: theme === "dark" ? "#f3f3f3" : "#1f1f1f" }}
              />
            </Button>
          </FilterContainer>
        </Dropdown.Menu>
      </Dropdown>
    </DropdownWrapper>
  );
};

export default FtsFilterDropdown;

const DropdownWrapper = styled.span`
  .fts-filter-dropdown {
    // TODO
  }
`;

const fadeInTop = keyframes`
  0% {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50);
    transform: translateY(-50);
    opacity: 1;
  }
`;

const FilterContainer = styled.div`
  margin: 0 5px;
  padding-left: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeInTop} 0.2s;
  input {
    padding: 0.25rem;
  }
  button {
    display: flex;
  }
`;
