import { useContext, useEffect, useState } from "react";
import { COMPARISON_OPTION } from "../../../types/comparison";
import { ComparisonContext } from "../../comparison/comparisonProvider";
import CounterSelect from "../counterSelect";

const ComparisonWeekOptions = () => {
  const [showCountControls, setShowCountControls] = useState(false);
  const [count, setCount] = useState(0);
  const [showSelectControls, setShowSelectControls] = useState(false);
  const [selectedTimeRange, setSelectedTimeRange] = useState("week");

  const { selectOption, option: selectedOption } =
    useContext(ComparisonContext);

  const getClassName = (selectionType) => {
    let isSelected = selectionType === selectedOption;
    let className = `list-item ${isSelected ? "selected" : ""}`;
    return className;
  };
  const handleSelection = (option: string) => {
    switch (option) {
      case COMPARISON_OPTION.SAME_RANGE_PREVIOUS:
        selectOption({ option: COMPARISON_OPTION.SAME_RANGE_PREVIOUS });
        break;
      case COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE:
        selectOption({
          option: COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE,
        });
        break;
      case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE:
        selectOption({
          option: COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE,
          config: {
            count,
          },
        });
        break;
      case COMPARISON_OPTION.CUSTOM:
      default:
        break;
    }
  };

  useEffect(() => {
    if (count) {
      handleSelection(COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE);
    }
  }, [count]);

  return (
    <div className="option-list-container">
      <span
        className={getClassName(COMPARISON_OPTION.SAME_RANGE_PREVIOUS)}
        onClick={() => handleSelection(COMPARISON_OPTION.SAME_RANGE_PREVIOUS)}>
        Previous week
      </span>
      <span
        className={getClassName(COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE)}
        onClick={() =>
          handleSelection(COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE)
        }>
        Same week last month
      </span>
      <span
        className={getClassName(COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE)}
        onClick={() =>
          handleSelection(COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE)
        }
        onMouseEnter={() => setShowCountControls(true)}
        onMouseLeave={() => setShowCountControls(false)}>
        Last{" "}
        <CounterSelect
          default={2}
          min={1}
          max={5}
          showControls={showCountControls}
          setCount={setCount}
        />{" "}
        weeks
      </span>
    </div>
  );
};

export default ComparisonWeekOptions;
