import { add, format, parse, parseISO } from "date-fns";
import { stringify } from "query-string";
import { convertDateRangeToEpochTimestamp } from "../../../helpers/utils";

const rearrangeColumns = (columns) => {
  let primaryColumn = columns.find((column) => column.primary_key);
  let remainingColumns = columns.filter((column) => !column.primary_key);
  if (primaryColumn) {
    return [primaryColumn, ...remainingColumns];
  }
  return columns;
};

const paginateUrl = (url, startLimit, endLimit) => {
  return url + `&pagination=${startLimit}:${endLimit}`;
};

/**
 * Applies timezone offset to given date with the offset value
 * @param sourceDate - In epoch timestamp (seconds)
 * @param offset - Offset value ex. 'UTC+04:00'
 *
 * returns `formatted date`
 */
const formatDateWithTimezoneOffset = (
  timestampS,
  timeZoneOffset,
  formatString = "yyyy-MM-dd HH:mm:ss"
) => {
  const [, sign, offsetHours, offsetMinutes] = timeZoneOffset.match(
    /([+-])(\d{2}):(\d{2})/
  );
  const totalOffsetInSeconds =
    (parseInt(offsetHours, 10) * 60 * 60 + parseInt(offsetMinutes, 10) * 60) *
    (sign === "-" ? -1 : 1);
  const timestampTZ = parseInt(timestampS) + totalOffsetInSeconds;
  const absoluteDate = new Date(timestampTZ * 1000);
  const weekDay = absoluteDate.getUTCDay();
  const day = absoluteDate.getUTCDate();
  const month = absoluteDate.getUTCMonth();
  const year = absoluteDate.getUTCFullYear();
  const hours = absoluteDate.getUTCHours();
  const minutes = absoluteDate.getUTCMinutes();
  const seconds = absoluteDate.getUTCSeconds();
  const milliseconds = absoluteDate.getUTCMilliseconds();

  const convertedDate = new Date(
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    milliseconds
  );
  return format(convertedDate, formatString);
};

const getDateRangeText = (filters) => {
  let text = "Report from ";
  if (filters[0] === filters[1]) {
    text = `Report as on ${format(
      parse(filters[0], "yyyy-MM-dd", new Date()),
      "do MMMM yyyy"
    )}`;
  } else {
    text = `Report from ${format(
      parse(filters[0], "yyyy-MM-dd", new Date()),
      "do MMMM yyyy"
    )} to ${format(
      parse(filters[1], "yyyy-MM-dd", new Date()),
      "do MMMM yyyy"
    )}`;
  }
  return text;
};

const removeColumnFilter = (
  filterCategory,
  widgetFilterData,
  appliedFilters,
  setAppliedColumnFilters
) => {
  let updatedFilterss = appliedFilters;
  let categoryIndex = appliedFilters.findIndex(
    (c) => c.filterKey === filterCategory.filterKey
  );
  if (categoryIndex !== -1) {
    let category = filterCategory;
    category.options = filterCategory.options.filter(
      (d) => d.label !== widgetFilterData.label
    );
    if (category.options.length > 0) {
      updatedFilterss[categoryIndex] = category;
    } else {
      updatedFilterss = appliedFilters.filter(
        (d) => d.filterKey !== filterCategory.filterKey
      );
    }
  }
  setAppliedColumnFilters([].concat(updatedFilterss));
};

const serializeFiltersToUrlParams: (
  url: string,
  filters: {
    locationFilters: number[];
    dateFilters: [string, string];
    timeFilters: [[number, number], [number, number]];
    ftsFilters: any[];
    columnFilters: any[];
    timezoneOffset: string;
    shouldUseEpocForTimeRange: boolean;
  }
) => string = (
  url,
  {
    locationFilters,
    dateFilters,
    timeFilters,
    ftsFilters,
    columnFilters,
    timezoneOffset,
    shouldUseEpocForTimeRange,
  }
) => {
  let queryParams = "?";
  if (locationFilters.length > 0) {
    if (queryParams.length > 1) {
      queryParams += "&";
    }
    queryParams += stringify(
      { locations: locationFilters },
      { arrayFormat: "comma" }
    );
  }
  if (dateFilters[0]) {
    if (queryParams.length > 1) {
      queryParams += "&";
    }
    let timeRange = [dateFilters[0], dateFilters[1]];
    if (shouldUseEpocForTimeRange) {
      timeRange = convertDateRangeToEpochTimestamp(
        dateFilters,
        timeFilters,
        timezoneOffset
      );
    }
    queryParams += stringify(
      {
        time_range: `${timeRange[0]}:${timeRange[1]}`,
      },
      { encode: false }
    );
  }
  if (ftsFilters && ftsFilters.length > 0) {
    for (let i = 0; i < ftsFilters.length; i++) {
      if (queryParams.length > 1) {
        queryParams += "&";
      }
      let filterQuery = ftsFilters[i].query;
      queryParams += stringify(
        {
          [`fts_${ftsFilters[i].key}`]: filterQuery,
        },
        { encode: false }
      );
    }
  }
  if (columnFilters && columnFilters.length > 0) {
    for (let i = 0; i < columnFilters.length; i++) {
      if (queryParams.length > 1) {
        queryParams += "&";
      }
      let ids = columnFilters[i].options.map((d) => d.value);
      queryParams += stringify(
        {
          [columnFilters[i].filterKey]: ids,
        },
        { encode: false, arrayFormat: "comma" }
      );
    }
  }
  return queryParams.length > 1 ? url + queryParams : url;
};

/**
 * **Workaround for local development** -
 * In local environment, the dates that is parsed from the query fired from
 * python odbc adapter returns ISO dates in invalid format.
 * This function will validate and correct that issue for local development
 *
 * `2022-01-01 05:54:49+00:00` -> `2022-01-03T09:27:22.000+00:00`
 * @param ISODateString
 * @returns ISODateString
 */
const correctISODateString = (incomingDate) => {
  if (incomingDate.includes("T")) {
    if (!incomingDate.includes(".")) {
      let updatedDate = incomingDate.split("+")[0];
      updatedDate = updatedDate.concat(".000+00:00");
      return updatedDate;
    }
    return incomingDate;
  }
  let updatedDate = incomingDate.replace(" ", "T");
  updatedDate = updatedDate.split("+")[0];
  if (updatedDate.includes(".")) {
    updatedDate = updatedDate.split(".")[0];
  }
  updatedDate = updatedDate.concat(".000+00:00");
  return updatedDate;
};

function formatDuration(duration: number) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  const hoursText = hours > 0 ? `${hours} hr${hours === 1 ? "" : "s"}` : "";
  const minutesText = minutes > 0 ? `${minutes} min` : "";
  const secondsText = seconds > 0 ? `${seconds} sec` : "";

  const parts = [hoursText, minutesText, secondsText];
  const durationText = parts.filter(Boolean).join(" ");

  return durationText || "0 sec";
}

export {
  rearrangeColumns,
  paginateUrl,
  getDateRangeText,
  removeColumnFilter,
  serializeFiltersToUrlParams,
  formatDateWithTimezoneOffset,
  correctISODateString,
  formatDuration,
};
