import { useMemo } from "react";
import useSWR from "swr";
import { configureUrlWithParams } from "../utils";
import offThreadQueryResponse from "../middleware/offThreadQueryResponse";
import { ENTITY_LOCK_TYPE } from "../../types/visibility";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

const useFetchDashboardData = ({ dashboard, filters, shouldFetch = false, }) => {
  const { company } = useSelector((state: RootState) => state);
  const timezoneOffset = company?.company_details?.time_zone_offset;
  const endpoint = useMemo(() => {
    if (dashboard && filters.locations.length > 0) {
      return configureUrlWithParams(`/api/v1/dar/${dashboard}/dashboard`, {
        ...filters,
        shouldUseEpocForTimeRange: company.shouldUseEpocForTimeRange,
        timezoneOffset,
      });
    }
    return null;
  }, [dashboard, JSON.stringify(filters)]);

  const { data, isLoading, mutate, error } = useSWR(
    shouldFetch ? endpoint : null,
    {
      use: [offThreadQueryResponse],
      revalidateOnFocus: false,
    }
  );

  const dashboardLockedStatus = useMemo(() => {
    let isEnabled = false;
    let config = {
      lockType: ENTITY_LOCK_TYPE.UNAUTHORIZED,
      marketingHtmlUrl: "https://sapaad.com/",
    };
    if (error?.response?.status === 423) {
      isEnabled = true;
      config.lockType =
        error?.response?.data?.lock_type ?? ENTITY_LOCK_TYPE.UNAUTHORIZED;
      if (
        error?.response?.data?.lock_type === ENTITY_LOCK_TYPE.MARKETING_MESSAGE
      ) {
        config.marketingHtmlUrl = error?.response?.data?.marketing_html_url;
      }
    }
    return { isEnabled, config };
  }, [error]);

  return {
    data: data?.dashboard_data,
    isLoading,
    error,
    retry: mutate,
    dashboardLockedStatus,
  };
};

export default useFetchDashboardData;
