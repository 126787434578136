import { useEffect, useRef } from "react";
import { usePub } from "../../helpers/hooks/pubSub";
import usePusher from "../../helpers/hooks/usePusher";

const PendingResultWaitEvents = ({ children }) => {
  const { pusherInstance } = usePusher();
  const publishMessage = usePub();
  const channel = useRef(null);

  useEffect(() => {
    channel.current = pusherInstance?.subscribe(
      "private-query_results_updates"
    );
    channel.current.bind("result_ready", (data: { result_id: string }) => {
      publishMessage(`pending_result_ready_${data.result_id}`, data);
    });
    return () => {
      channel.current = null;
      pusherInstance?.unsubscribe("private-query_results_updates");
    };
  }, []);

  return <>{children}</>;
};

export default PendingResultWaitEvents;
