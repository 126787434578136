import { useEChartsOption } from "../../../../helpers/hooks/useEchartsOption";
import { numFormatter } from "../../../../helpers/utils";
import BaseChart from "../../../baseChart";
import { chartIcons } from "../../../baseChart/utils";
import { formatTooltip } from "./utils";

interface IStackedBarChartProps {
  height: number;
  width: number;
  config: {
    valueFormatter: string;
    showLegends: boolean;
    showStackLabels: boolean;
    orientation: string;
    valueAxisName: string;
    categoryAxisName: string;
  };
  data: {
    categories: string[];
    series: { name: string; data: number[] }[];
  };
}

const StackedBarChart = ({
  config,
  data,
  height,
  width,
}: IStackedBarChartProps) => {
  const option = useEChartsOption(() => {
    let legend = {
      show: config.showLegends,
      type: "scroll",
    };
    let grid = {
      left: width < 650 ? "20%" : "10%",
      right: "5%",
    }
    let tooltip = {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
      formatter: (p) =>
        formatTooltip({
          seriesContent: p,
          valueFormatter: config.valueFormatter,
          categoryName: config.categoryAxisName,
          valueName: config.valueAxisName,
        }),
    };
    let categoryAxis = {
      type: "category",
      data: data.categories,
      axisPointer: {
        type: "shadow",
        show: true,
        label: {
          show: true
        }
      },
      axisLabel: {
        show: true,
      },
    };
    let valueAxis = {
      name: config.valueAxisName,
      type: "value",
      axisPointer: {
        type: "line",
        show: true,
        triggerTooltip: false,
        label: {
          show: true
        }
      },
      axisLabel: {
        show: true,
        formatter: (params) =>
          `${numFormatter(params)} ${config.valueFormatter}`,
      },
    };
    let chartOrientation =
      config.orientation === "vertical"
        ? {
            xAxis: categoryAxis,
            yAxis: valueAxis,
          }
        : { yAxis: categoryAxis, xAxis: valueAxis };
    let seriesTotal: any = [];
    data.series.map((i) => {
      i.data.map((j, index) => {
        if(j){
          seriesTotal[index] = seriesTotal[index] ? seriesTotal[index] + j : j;
        } else {
          seriesTotal[index] = seriesTotal[index] ? seriesTotal[index] : 0;
        }
      })
    });
    let series = data.series.map((s, index) => ({
      name: s.name,
      type: "bar",
      stack: "total",
      label: {
        show: config.showStackLabels,
        formatter: function(params){
          if(params.value < seriesTotal[index]/10){
            return '';
          }
        }
      },
      emphasis: {
        focus: "none",
        label: {
          show: config.showStackLabels,
        },
      },
      data: s.data,
    }));
    let dataZoom =
      data.series.length > 1
        ? [
            {
              type: "slider",
              height: 20,
              borderColor: "none",
              startValue: 0,
              endValue: 9,
              moveHandleSize: -20,
              backgroundColor: "none",
              fillerColor: "#F1EDED",
              moveHandleStyle: {
                color: "grey",
                handleSize: 5,
              },
              dataBackground: {
                areaStyle: {
                  color: "none",
                  opacity: 0,
                },
                lineStyle: {
                  color: "none",
                  opacity: 0,
                },
              },
              z: 2,
              handleIcon: "circle",
              handleSize: 25,
              moveHandleIcon: chartIcons.scrollbarMoveHandle,
            },
            {
              type: "slider",
              height: 5,
              startValue: 0,
              endValue: 10,
              moveHandleSize: -20,
              backgroundColor: "#F1EDED",
              moveHandleStyle: {
                color: "grey",
                handleSize: 5,
              },
              dataBackground: {
                areaStyle: {
                  color: "none",
                  opacity: 0,
                },
                lineStyle: {
                  color: "none",
                  opacity: 0,
                },
              },
              z: 0,
              handleIcon: "",
              handleSize: 0,
              brushSelect: false,
            },
          ]
        : [];
    return Object.assign(
      {},
      { legend, grid, tooltip, series, dataZoom },
      chartOrientation
    );
  }, [JSON.stringify(config), JSON.stringify(data), width]);

  return (
    <BaseChart chartType="bar" options={option} height={height} width={width} />
  );
};

export default StackedBarChart;
