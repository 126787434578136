import { createContext, useMemo, useState } from "react";
import DashboardLivePresenceEvents from "./dashboardLivePresenceEvents";
import DashboardPresenceEvents from "./dashboardPresenceEvents";
import DashboardPrivateEvents from "./dashboardPrivateEvents";
import { format, parse, setHours, setMinutes } from "date-fns";

export const DashboardEventsContext = createContext<{
  setLocationIds: any;
  setStartDate: any;
  setStartTime: any;
  setDashboard: any;
  isLiveDashboard: boolean;
  updateBusinessStartDateTime: (date?: string, time?: [number, number]) => void;
}>(undefined as any);

const DashboardEvents = ({ children }) => {
  const [locationIds, setLocationIds] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [startTime, setStartTime] = useState<[number, number]>([0, 0]);
  const [dashboard, setDashboard] = useState<string>("");
  const [businessStartDate, setBusinessStartDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [businessStartTime, setBusinessStartTime] = useState<[number, number]>([
    0, 0,
  ]);

  const updateBusinessStartDateTime = (
    date: string = format(new Date(), "yyyy-MM-dd"),
    time: [number, number] = [0, 0]
  ) => {
    setBusinessStartDate(date);
    setBusinessStartTime(time);
  };

  const isCurrentDay = useMemo(() => {
    let businessStartDateTime = setHours(
      setMinutes(
        parse(businessStartDate, "yyyy-MM-dd", new Date()),
        businessStartTime[1]
      ),
      businessStartTime[0]
    );
    let startDateTime = setHours(
      setMinutes(parse(startDate, "yyyy-MM-dd", new Date()), startTime[1]),
      startTime[0]
    );
    return startDateTime >= businessStartDateTime;

  }, [startDate, startTime, businessStartDate, businessStartTime]);

  return (
    <>
      {locationIds.length > 0 && startDate && dashboard ? (
        <>
          <DashboardPresenceEvents
            dashboardName={dashboard}
            locationIds={locationIds}
          />
          {isCurrentDay ? (
            <>
              <DashboardPrivateEvents
                dashboardName={dashboard}
                locationIds={locationIds}
              />
              <DashboardLivePresenceEvents
                dashboardName={dashboard}
                locationIds={locationIds}
              />
            </>
          ) : null}
        </>
      ) : null}
      <DashboardEventsContext.Provider
        value={{
          setLocationIds,
          setStartDate,
          setStartTime,
          setDashboard,
          isLiveDashboard: isCurrentDay,
          updateBusinessStartDateTime,
        }}>
        {children}
      </DashboardEventsContext.Provider>
    </>
  );
};

export default DashboardEvents;
