import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CalendarView from "./calendarView";
import MonthPickView from "./monthPickView";
import YearPickView from "./yearPickView";
import TimePickView from "./timepickView";
import DateTimeToggle from "./dateTimeToggle";

const InlineDatepicker = ({
  currDate,
  setDate,
  pickType,
  hour,
  setHour,
  minute,
  setMinute,
}) => {
  const [month, setMonth] = useState(new Date().getMonth());
  const [day, setDay] = useState(new Date().getDate());
  const [year, setYear] = useState(new Date().getFullYear());

  const [showCalendarView, setShowCalendarView] = useState(true);
  const [showMonthView, setShowMonthView] = useState(false);
  const [showYearView, setShowYearView] = useState(false);
  const [showTimePickView, setShowTimePickView] = useState(false);

  const preTimePickerViewState = useRef<boolean[]>([
    showCalendarView,
    showMonthView,
    showYearView,
  ]);

  useEffect(() => {
    if (currDate) {
      setDay(new Date(currDate).getDate());
      setMonth(new Date(currDate).getMonth());
      setYear(new Date(currDate).getFullYear());
    }
  }, [currDate]);

  const handleDateChange = (date: Date) => {
    setDay(new Date(date).getDate());
    setMonth(new Date(date).getMonth());
    setYear(new Date(date).getFullYear());
  };

  const handleMonthChange = (date: Date) => {
    setMonth(new Date(date).getMonth());
    setYear(new Date(date).getFullYear());
    setShowMonthView(false);
    setShowCalendarView(true);
    setShowYearView(false);
  };

  const handleYearChange = (date: Date) => {
    setYear(new Date(date).getFullYear());
    setShowMonthView(false);
    setShowCalendarView(true);
    setShowYearView(false);
  };

  const handleHourChange = (value: string) => {
    setHour(parseInt(value));
  };

  const handleMinuteChange = (value: string) => {
    setMinute(parseInt(value));
  };

  const handleDateSelect = (selectedDate: Date) => {
    setDate(selectedDate);
  };

  const handleShowMonthView = () => {
    setShowMonthView(true);
    setShowCalendarView(false);
    setShowYearView(false);
  };

  const handleShowYearView = () => {
    setShowMonthView(false);
    setShowCalendarView(false);
    setShowYearView(true);
  };

  const handleDateTimeSelectionToggle = () => {
    if (view.isTimePicker) {
      // Load last view state when time picker was hidden
      const [shouldShowCalendar, shouldShowMonthPicker, shouldShowYearPicker] =
        preTimePickerViewState.current;
      setShowMonthView(shouldShowMonthPicker);
      setShowCalendarView(shouldShowCalendar);
      setShowYearView(shouldShowYearPicker);
      setShowTimePickView(false);
    } else {
      // Save last view state before showing time picker view
      preTimePickerViewState.current = [
        showCalendarView,
        showMonthView,
        showYearView,
      ];
      setShowMonthView(false);
      setShowCalendarView(false);
      setShowYearView(false);
      setShowTimePickView(true);
    }
  };

  const view = {
    isTimePicker:
      showTimePickView && !showCalendarView && !showMonthView && !showYearView,
    isCalendar:
      !showTimePickView && showCalendarView && !showMonthView && !showYearView,
    isMonthPicker:
      !showTimePickView && !showCalendarView && showMonthView && !showYearView,
    isYearPicker:
      !showTimePickView && !showCalendarView && !showMonthView && showYearView,
  };

  return (
    <Wrapper>
      {view.isTimePicker ? (
        <TimePickView
          hour={hour}
          minute={minute}
          onHourChange={handleHourChange}
          onMinuteChange={handleMinuteChange}
          selectedDate={new Date(year, month, day)}
          onDateChange={handleDateChange}
        />
      ) : null}
      {view.isCalendar ? (
        <CalendarView
          selectedDate={{ year, month, day }}
          onDateChange={handleDateChange}
          onDateSelect={handleDateSelect}
          onShowMonthView={handleShowMonthView}
          pickerType={pickType}
        />
      ) : null}
      {view.isMonthPicker ? (
        <MonthPickView
          selectedDate={{ year, month, day }}
          onMonthChange={handleMonthChange}
          onShowYearView={handleShowYearView}
        />
      ) : null}
      {view.isYearPicker ? (
        <YearPickView
          selectedDate={{ year, month, day }}
          onYearChange={handleYearChange}
        />
      ) : null}
      <DateTimeToggle
        showTimePicker={!view.isTimePicker}
        onClick={handleDateTimeSelectionToggle}
      />
    </Wrapper>
  );
};

export default InlineDatepicker;

const Wrapper = styled.div`
  margin: 15px 0px 0;
  flex: 1;
  border: 1px solid ${({ theme }) => theme.border_color};
  border-radius: 5px;
`;
