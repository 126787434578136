import { useContext, useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useElementSize } from "usehooks-ts";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import { WidgetContext } from "../../widgetContext";
import LineChartVisual from "./lineChart";
import Widget from "../../baseWidget";
import { RootState } from "../../../state/store";

interface ILineChartWidgetProps {}

const LineChartWidget = (props: ILineChartWidgetProps) => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [widgetRef, contentDimensions] = useElementSize();
  const dispatch = useDispatch();

  const widget = useContext(WidgetContext);

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  const widgetSeriesData = useMemo(() => {
    if (widgetData?.widget_data) {
      return widgetData.widget_data.data.series;
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (widget.widgetFilterConfig.enableFilters && !widgetFilterExists) {
        dispatch(
          addWidgetToFilterList({
            name: widget.widgetFilterConfig.filterTitle,
            filterKey: widget.widgetFilterConfig.widgetFilterKey,
            options: widget.widgetData.widget_data.data.series.map((d) => ({
              id: d.id,
              name: d.category,
            })),
          })
        );
      }
    }
  }, [widget.widgetData]);

  const dimensions = useMemo(() => {
    let chart = { width: 0, height: 0 };
    let hPadding = 50;
    let vPadding = 90;
    if (contentDimensions.height > 0) {
      chart.width = contentDimensions.width - hPadding;
      chart.height = contentDimensions.height - vPadding;
    }
    return { chart };
  }, [JSON.stringify(contentDimensions)]);

  return (
    <Widget ref={widgetRef}>
      <Widget.Header>
        <Widget.Title>{widgetData?.title}</Widget.Title>
        <Widget.Controls></Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {widgetSeriesData.length > 0 ? (
          <LineChartVisual
            data={widgetData.widget_data}
            height={dimensions.chart.height}
            width={dimensions.chart.width}
          />
        ) : (
          <Widget.EmptyData
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 90}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default LineChartWidget;
