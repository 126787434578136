import styled from "styled-components";

const SankeyTable = ({ data, width, height }) => {
  const columns = data.config.table_configs.column_names;
  return (
    <StyledTable $width={width} $height={height}>
      <table>
        <thead>
          <tr>
            <th>{columns.source}</th>
            <th>{columns.destination}</th>
            <th>{columns.item}</th>
            <th>
              {columns.value} {`(${data.config.value_formatter})`}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.data.series.map((s) => (
            <tr key={crypto.randomUUID()}>
              <td>{s.source}</td>
              <td>{s.destination}</td>
              <td>{s.item}</td>
              <td>{s.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledTable>
  );
};

export default SankeyTable;

const StyledTable = styled.div<{ $width: number; $height: number }>`
  width: ${(props) => `${props.$width}px`};
  height: ${(props) => `${props.$height}px`};
  overflow: scroll;
  table {
    width: ${(props) => `${props.$width}px`};
    border-spacing: 0;
    background-color: ${({ theme }) => theme.widget.background};
    border-collapse: separate;
    > thead {
      height: 55px;
      > tr {
        > th {
          margin-left: 20px;
          font-size: 16px;
          color: ${({ theme }) => theme.text};
          font-weight: 700;
          position: relative;
          white-space: nowrap;
          padding: 0 0.5rem;
          border-bottom: 2px solid #e4e4e4;
          top: 0px;
          position: sticky;
          background-color: ${({ theme }) => theme.widget.background};
        }
      }
    }
    > tbody {
      > tr {
        > td {
          font-size: 15px;
          font-weight: 400;
          line-height: 21.48px;
          padding: 0 0.5rem;
          white-space: nowrap;
          border-bottom: 1px solid #e4e4e4;
        }
        height: 49px;
        &.sticky-footer {
          margin-left: 20px;
          font-size: 16px;
          color: ${({ theme }) => theme.text};
          font-weight: 900;
          position: relative;
          white-space: nowrap;
          padding: 0 0.5rem;
          border-top: 2px solid #343434;
          bottom: 0px;
          position: sticky;
          background-color: ${({ theme }) => theme.widget.background};
          > td {
            border-top: 1px solid #505050;
            border-bottom: none;
          }
        }
      }
    }
  }
`;
