import { useElementSize } from "usehooks-ts";
import Widget from "../../../../components/baseWidget";
import styled from "styled-components";
import StreamIcon from "./streamIcon";
import { useContext, useMemo } from "react";
import { HappeningsContext } from "../../contexts/happeningsContext";
import EventCard from "../eventCard";
import EmptyStateFallback from "./emptyStateFallback";

const HappeningsEventStreamWidget = () => {
  const [containerRef, containerDims] = useElementSize();
  const { eventsData } = useContext(HappeningsContext);

  const contentDims = useMemo(() => {
    let dims = { height: 0, width: 0 };
    if (containerDims.height) {
      dims.height = containerDims.height - 50;
      dims.width = containerDims.width - 50;
    }
    return dims;
  }, [containerDims]);

  return (
    <Widget ref={containerRef}>
      <WidgetContentWrapper
        style={{
          height: `${contentDims}px`,
          width: `${contentDims}px`,
        }}>
        <div className="widget-title">
          <span className="icon">
            <StreamIcon />
          </span>
          <span className="title">What's Happening</span>
        </div>
        <div
          className="widget-content"
          style={{ height: `${contentDims.height - 40}px` }}>
          {eventsData.length > 0 ? (
            eventsData
              .sort((a, b) => b.timestamp - a.timestamp)
              .map((d, i) => (
                <EventCard
                  key={`${d.timestamp}.${crypto.randomUUID()}`}
                  icon={d.icon}
                  iconUrl={d.icon_url}
                  title={d.title}
                  message={d.message}
                  timestamp={d.timestamp}
                  variant={d.variant}
                />
              ))
          ) : (
            <EmptyStateFallback />
          )}
        </div>
      </WidgetContentWrapper>
    </Widget>
  );
};

export default HappeningsEventStreamWidget;

const WidgetContentWrapper = styled.div`
  .widget-title {
    display: flex;
    position: relative;
    bottom: 10px;
    height: 40px;
    align-items: center;
    .title {
      margin-top: 5px;
      font-size: 18px;
      color: #70D380;
    }
  }
  .widget-content {
    overflow: scroll;
  }
`;
