import {
  ForwardRefRenderFunction,
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { useWidgetContext } from "../../helpers/hooks/useWidgetContext";
import {
  TbSortAscendingNumbers,
  TbSortDescendingNumbers,
} from "react-icons/tb";
import { FaCaretDown, FaMagnifyingGlassArrowRight } from "react-icons/fa6";
import WidgetLoading from "../widgetLoading";
import WidgetLoadError from "../widgetLoadError";
import WidgetEmptyData from "../widgetEmptyData";
import LastUpdatedFooter from "../widgetLastUpdatedFooter";
import { format } from "date-fns";
import {
  focusLocationFilter,
  focusSecondaryFilter,
} from "../../state/features/analytics/actions";
import AiAdvisorCta from "../../features/ai/aiAdvisorCta";
import { RootState, dispatchAction } from "../../state/store";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdHelpOutline } from "react-icons/md";
import { useSelector } from "react-redux";


interface WidgetProps {
  children: ReactNode;
  ref: any;
  type?: string;
}

interface WidgetHeaderProps {
  children: ReactNode;
}

interface WidgetContentProps {
  children: ReactNode;
  style?: React.CSSProperties;
}

type WidgetComponent = ForwardRefRenderFunction<HTMLDivElement, WidgetProps> & {
  Header: React.FC<WidgetHeaderProps>;
  Content: React.FC<WidgetContentProps>;
  DataFilterControl: React.FC<DataFilterControlProps>;
  Title: React.FC<any>;
  Controls: React.FC<any>;
  ExtendedAReportCta: React.FC<any>;
  EmptyData: React.FC<any>;
  AiSuggestionCta: React.FC<any>;
};

const Widget: WidgetComponent = forwardRef<HTMLDivElement, WidgetProps>(
  ({ children, type = "single", ...props }, ref) => {
    const widget = useWidgetContext();

    if (widget.widgetLoading) {
      return (
        <WidgetContainer ref={ref} $type={type} {...props}>
          <WidgetLoading />
        </WidgetContainer>
      );
    }

    if (widget.widgetLoadError) {
      return (
        <WidgetContainer ref={ref} $type={type} {...props}>
          <WidgetLoadError />
        </WidgetContainer>
      );
    }

    if (!widget.widgetData) {
      return (
        <WidgetContainer ref={ref} $type={type} {...props}></WidgetContainer>
      );
    }

    return (
      <WidgetContainer
        ref={ref}
        $type={type}
        className={widget?.isTimelessWidget ? "outline" : ""}
        {...props}>
        {children}
        {widget.isTimelessWidget ? (
          <LastUpdatedFooter
            date={format(widget.lastUpdatedTime, "do MMMM yyyy")}
            time={format(widget.lastUpdatedTime, "hh:mm aaa")}
          />
        ) : null}
      </WidgetContainer>
    );
  }
);

Widget.Header = ({ children }) => {
  return <div className="widget-header">{children}</div>;
};

Widget.Title = ({ children, onClick }) => {
  const { filterUsedByWidget } = useWidgetContext();
  const widgetFilters = useSelector(
    (state: RootState) => state.filters.widgets
  );

  const appliedWidgetFilterLabel = useMemo(() => {
    let label = "";
    if (widgetFilters.length && filterUsedByWidget) {
      let filterData = widgetFilters.find((d) => d.key == filterUsedByWidget);
      if (
        filterData &&
        filterData?.data &&
        Array.isArray(filterData.data) &&
        filterData.data.length
      ) {
        label = filterData.data[0].name;
      }
    }
    return label;
  }, [JSON.stringify(widgetFilters), filterUsedByWidget]);

  const handleFilterItemClick = () => {
    dispatchAction(focusSecondaryFilter(filterUsedByWidget));
  }

  return (
    <>
      <span className="widget-title" onClick={onClick}>
        {children}{" "}
        {appliedWidgetFilterLabel ? (
          <>
            <span className="pretext">{" : "}</span>
            <span
              className="widget-filtered-by"
              onClick={handleFilterItemClick}>
              {appliedWidgetFilterLabel}
            </span>
            <FaCaretDown
              color="#0f80d1"
              style={{
                fontSize: "12px",
                marginLeft: "5px",
              }}
            />
          </>
        ) : null}
      </span>
    </>
  );
};

Widget.Content = ({ children, style = {} }) => {
  const { overrideWidgetContent, widgetInputRequired, toolTipMessage } =
    useWidgetContext();
  const [filterLinkConfig, setFilterLinkConfig] = useState<{
    startTextFragment: string;
    endTextFragment: string;
    formattedText: string;
    filterKey: string;
  } | null>(null);

  useEffect(() => {
    if (overrideWidgetContent) {
      const regexMatch = overrideWidgetContent.match(/\[(.*)\]\((.*)\)/);
      if (regexMatch) {
        const [searchString, formattedText, filterKey] = regexMatch;
        // Assuming that there will be only one substring, split the text
        const [startTextFragment, endTextFragment] =
          overrideWidgetContent.split(searchString);
        setFilterLinkConfig({
          startTextFragment,
          endTextFragment,
          formattedText,
          filterKey,
        });
      }
    }
  }, [overrideWidgetContent]);

  const handleLinkOnClick = () => {
    if (filterLinkConfig?.filterKey !== "location_ids") {
      dispatchAction(focusSecondaryFilter(filterLinkConfig?.filterKey));
    } else {
      dispatchAction(focusLocationFilter(filterLinkConfig?.filterKey));
    }
  };

  return (
    <div className="widget-content" style={{ height: "100%", ...style }}>
      {overrideWidgetContent ? (
        <div className="override-content">
          {overrideWidgetContent && widgetInputRequired ? (
            filterLinkConfig ? (
              <span className="override-message">
                {filterLinkConfig.startTextFragment}
                <span className="filter-link" onClick={handleLinkOnClick}>
                  {filterLinkConfig.formattedText}
                </span>
                {filterLinkConfig.endTextFragment}{" "}
                {toolTipMessage ? (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    overlay={
                      <Tooltip id="override-message-tooltip">
                        {toolTipMessage}
                      </Tooltip>
                    }>
                    <span>
                      <MdHelpOutline
                        style={{
                          fontSize: "19px",
                          position: "relative",
                          bottom: "2px",
                        }}
                      />
                    </span>
                  </OverlayTrigger>
                ) : null}
              </span>
            ) : (
              <span className="override-message">{overrideWidgetContent}</span>
            )
          ) : (
            <span className="override-message">{overrideWidgetContent}</span>
          )}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

Widget.Controls = ({ children }) => {
  return <>{children}</>;
};

Widget.EmptyData = ({ height, width }) => {
  return <WidgetEmptyData height={height} width={width} />;
};

type DataFilterControlProps = {
  enableFiltering?: boolean;
  enableSorting?: boolean;
  seriesData: any[];
  updateSeriesData(modifiedSeriesData: any[]): void;
  sortKey?: string;
  filterOption?: string;
};

Widget.DataFilterControl = ({
  enableFiltering = false,
  enableSorting = false,
  seriesData = [],
  updateSeriesData,
  sortKey,
  filterOption = "All",
}: DataFilterControlProps) => {
  const [modifiedSeriesData, setModifiedSeriesData] = useState(seriesData);
  const [selectedFilterOption, setSelectedFilterOption] = useState("All");
  const [sortAscending, setSortAscending] = useState(false);
  const widgetFilterPresets = [5, 10, 15, 20];

  useEffect(() => {
    let data = seriesData;
    if (enableSorting) {
      data = sortSeriesData(sortKey, sortAscending, data);
    }
    if (enableFiltering) {
      data = filterSeriesData(filterOption, data);
    }
    setModifiedSeriesData(data);
    updateSeriesData(data);
  }, []);

  const handleFilterOptionSelection = (e) => {
    let option = e.target.value;
    setSelectedFilterOption(option);
    let data = filterSeriesData(option, seriesData);
    if (enableSorting) {
      data = sortSeriesData(sortKey, sortAscending, data);
    }
    setModifiedSeriesData(data);
    updateSeriesData(data);
  };

  const handleSortModeSelection = () => {
    setSortAscending((mode) => !mode);
    let data = sortSeriesData(sortKey, !sortAscending, modifiedSeriesData);
    updateSeriesData(data);
  };

  const sortSeriesData = (sortKey, ascending = sortAscending, seriesData) => {
    let sortedSeriesData = seriesData.sort((a, b) => {
      if (!ascending) {
        return b[sortKey] - a[sortKey];
      }
      return a[sortKey] - b[sortKey];
    });
    return sortedSeriesData;
  };

  const filterSeriesData = (option = selectedFilterOption, seriesData) => {
    let filteredSeriesData = seriesData;
    if (option === "All") {
      filteredSeriesData = seriesData;
    } else {
      filteredSeriesData = seriesData.filter(
        (dat, idx) => idx <= parseInt(option) - 1
      );
    }
    return filteredSeriesData;
  };

  return (
    <FilterControlWrapper>
      {enableFiltering ? (
        <select
          className="filter-select"
          onChange={handleFilterOptionSelection}>
          {widgetFilterPresets.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))}
          <option value="All">All</option>
        </select>
      ) : null}
      {enableSorting ? (
        <div className="sort-option" onClick={handleSortModeSelection}>
          {sortAscending ? (
            <TbSortAscendingNumbers title="sort in descending order" />
          ) : (
            <TbSortDescendingNumbers
              title="sort in ascending order"
              width={"14px"}
            />
          )}
        </div>
      ) : null}
    </FilterControlWrapper>
  );
};

Widget.ExtendedAReportCta = ({ additionalFilters = [] }) => {
  const { enableExtendedAReport, onExtendedAClick } = useWidgetContext();
  return (
    <ExtendedAReportCtaWrapper>
      {enableExtendedAReport ? (
        <div
          className="extended-report-option"
          onClick={() => onExtendedAClick(additionalFilters)}
          title="more details">
          <FaMagnifyingGlassArrowRight fontSize={"18px"} />
        </div>
      ) : null}
    </ExtendedAReportCtaWrapper>
  );
};

Widget.AiSuggestionCta = () => {
  const {
    isAiSuggestionLoading,
    isAiSuggestionEnabled,
    isAiSuggestionLoadError,
    getAiSuggestion,
  } = useWidgetContext();
  if (isAiSuggestionEnabled) {
    return (
      <AiAdvisorCta
        showError={isAiSuggestionLoadError}
        showLoadingIndicator={isAiSuggestionLoading}
        onClick={() => getAiSuggestion()}
      />
    );
  }
  return null;
};

export default Widget;

const WidgetContainer = styled.div<{ $type: string }>`
  border: 1px solid ${({ theme }) => theme.widget_group.border};
  border-radius: 20px;
  background-color: ${(props) =>
    props.$type === "group"
      ? props.theme.widget_group.background
      : props.theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  &.outline {
    border-radius: 20px;
    border: 10px solid rgb(172, 213, 202);
  }
  width: 100%;
  height: 100%;
  padding: 25px;
  .widget-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    .widget-title {
      flex: 1;
      font-size: 1.3rem;
      text-decoration: underline;
      .live-badge {
        margin-left: 10px;
        margin-bottom: 5px;
      }
      .widget-filtered-by {
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: 700;
        color: #0f80d1;
        text-decoration: underline;
      }
    }
  }
  .widget-content {
    .override-content {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .override-message {
        font-size: 18px;
        .filter-link {
          cursor: pointer;
          color: #6699cc;
          font-weight: 600;
          text-decoration: underline;
          text-decoration-thickness: 2px;
        }
      }
    }
  }
`;

const FilterControlWrapper = styled.div`
  display: flex;
  .filter-select {
    box-sizing: border-box;
    height: 30px;
    border-radius: 8px;
    font-size: 14px;
    border: 1px solid #bebebe;
    background-color: ${({ theme }) => theme.widget.background};
    color: ${({ theme }) => theme.text};
  }
  .sort-option {
    box-sizing: border-box;
    height: 35px;
    width: 35px;
    color: ${({ theme }) => theme.button_plain.text};
    cursor: pointer;
    border: 1.5px solid ${({ theme }) => theme.button_plain.border};
    &:hover {
      color: ${({ theme }) => theme.button_plain.hover_text};
      background: ${({ theme }) => theme.button_plain.hover};
    }
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 2px 8px;
    padding: 0px;
    align-items: center;
  }
`;

const ExtendedAReportCtaWrapper = styled.div`
  .extended-report-option {
    margin-right: 7px;
    box-sizing: border-box;
    height: 35px;
    width: 35px;
    cursor: pointer;
    color: ${({ theme }) => theme.button_plain.text};
    border: 1.5px solid ${({ theme }) => theme.button_plain.border};
    &:hover {
      color: ${({ theme }) => theme.button_plain.hover_text};
      background: ${({ theme }) => theme.button_plain.hover};
    }
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 2px 8px;
    padding: 0px;
    align-items: center;
  }
`;
