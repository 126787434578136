import { useMemo } from "react";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { createColumnHelper, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import styled from "styled-components";
import { formatDateWithTimezoneOffset } from "../../../helpers/utils";

export default function TabularList({ data, width, height }) {
  const columnHelper = createColumnHelper();

  const getAggregatedData = (rowData, columnData) => {
    let summaryData = columnData.map((c, i) => {
      if (i === 0) {
        return "Total";
      }
      if (c.type === "Number" && c.aggregate) {
        let total = rowData.reduce((total, d) => {
          return total + parseFloat(d[c.accessor]);
        }, 0);
        return total.toLocaleString();
      } else {
        return "";
      }
    });
    return summaryData;
  };

  const formatColumnHeader = ({ header, valueFormatter }) => {
    if (valueFormatter) {
      return `${header} (${valueFormatter})`;
    }
    return header;
  };

  const formatCell = ({ cell }) => {
    let cellContent = cell.renderValue();
    let columnType = cell.column.columnDef.meta.type ?? "Text";
    let timezoneOffset = cell.row.original?.offset;
    if (columnType === "Date") {
      if (timezoneOffset) {
        cellContent = formatDateWithTimezoneOffset(
          cell.renderValue().toString(),
          timezoneOffset,
          "yyyy-MM-dd hh:mm:ss aa"
        );
      }
    }
    if (columnType === "Number") {
      cellContent = parseFloat(cell.renderValue()).toLocaleString();
    }
    return cellContent;
  };

  const { rows, columns, aggregatedData, showTableHeader } = useMemo(() => {
    let rows = [];
    let columns = [];
    let aggregatedData = [];
    let showTableHeader = true;
    if (data?.config) {
      showTableHeader = data?.config?.show_table_header || false;
    }
    if (data?.data) {
      columns = data.data.columns.map((c) =>
        columnHelper.accessor(c.accessor, {
          header: c.header,
          cell: (info) => info.getValue(),
          meta: c,
        })
      );
      rows = data.data.rows;
      aggregatedData = getAggregatedData(data.data.rows, data.data.columns);
    }
    return { rows, columns, aggregatedData, showTableHeader };
  }, [data]);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <TableWrapper style={{ width, height }}>
      <table className="bordered">
        {showTableHeader ? (
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <th>
                  <span>#</span>
                </th>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {formatColumnHeader({
                      header: header.column.columnDef.header,
                      valueFormatter:
                        header.column.columnDef.meta.value_formatter,
                    })}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        ) : null}
        <tbody>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr key={row.id}>
              <td>
                <span>{rowIndex + 1}</span>
              </td>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{formatCell({ cell })}</td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            {aggregatedData.map((data, i) => (
              <td key={i}>{data}</td>
            ))}
          </tr>
        </tfoot>
      </table>
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  background-color: ${({ theme }) => theme.widget.background};
  padding-left: 10px;
  overflow: scroll;
  table {
    border-spacing: 0;
    background-color: ${({ theme }) => theme.widget.background};
    border-collapse: separate;
    > thead {
      height: 55px;
      z-index: 100;
      position: sticky;
      > tr {
        > th {
          margin-left: 20px;
          font-size: 16px;
          color: ${({ theme }) => theme.text};
          font-weight: 700;
          position: relative;
          white-space: nowrap;
          padding: 0 0.5rem;
          border-bottom: 2px solid #e4e4e4;
          top: 0px;
          position: sticky;
          background-color: ${({ theme }) => theme.widget.background};
          &:first-child {
            > span {
              opacity: 0.4;
            }
          }
        }
      }
    }
    > tbody {
      > tr {
        > td {
          font-size: 15px;
          font-weight: 400;
          line-height: 21.48px;
          padding: 0 0.5rem;
          white-space: nowrap;
          border-bottom: 1px solid #e4e4e4;
          &:first-child {
            > span {
              opacity: 0.4;
            }
          }
        }
        height: 49px;
      }
    }
    > tfoot {
      bottom: 0px;
      height: 55px;
      position: sticky;
      > tr {
        > td {
          margin-left: 20px;
          font-size: 16px;
          color: ${({ theme }) => theme.text};
          font-weight: 700;
          position: relative;
          white-space: nowrap;
          padding: 0 0.5rem;
          border-top: 2px solid #e4e4e4;
          top: 0px;
          position: sticky;
          background-color: ${({ theme }) => theme.widget.background};
        }
      }
    }
  }
`;
