import { useMemo } from "react";
import { HappeningsContext } from "../contexts/happeningsContext";
import { useFetchHappeningsData } from "../hooks/useFetchHappeningsData";
import { getEnvValueFor } from "../../../helpers/utils";
import { useParams } from "react-router-dom";

// variants = positive, neutral, negative

const HappeningsProvider = ({ children }) => {
  const refreshInterval = getEnvValueFor("HAPPENINGS_POLL_INTERVAL");

  const routeMatch = useParams()

  // Restrict data polling to d-level dashboards
  const enableDataPolling = useMemo(
    () => routeMatch.dashboard && !routeMatch.widget,
    [routeMatch]
  );

  const { data } = useFetchHappeningsData({
    refreshInterval:
      enableDataPolling && refreshInterval
        ? parseInt(refreshInterval) * 1000
        : undefined,
  });
  const eventsData = useMemo(() => data ?? [], [data]);

  return (
    <HappeningsContext.Provider value={{ eventsData }}>
      {children}
    </HappeningsContext.Provider>
  );
};

export default HappeningsProvider;
