import Markdown from "react-markdown";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useMeasure } from "react-use";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { IoIosCloseCircle } from "react-icons/io";

const AiCommentaryView = ({ show, closeModal, data }) => {
  const [markdownContent, setMarkdownContent] = useState("");
  let { current: interval } = useRef<NodeJS.Timer>(null);
  const [modalContainerRef, modalContainerDims] = useMeasure(); // Get modal container height and width
  const theme = useSelector((state: RootState) => state.theme.mode);

  const { current: latency } = useRef<number>(50); // in milliseconds
  const { current: splitChar } = useRef(" ");

  useEffect(() => {
    if (data?.result) {
      const splitByChar = data?.result.split(splitChar);
      let currentIndex = 0;
      const processedWords = [];
      interval = setInterval(() => {
        if (currentIndex < splitByChar.length) {
          processedWords.push(splitByChar[currentIndex]);
          setMarkdownContent(processedWords.join(splitChar));
          currentIndex++;
        }
      }, latency);
    }
    return () => {
      // Cleanup
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [data]);

  return (
    <Modal
      dialogClassName="custom curved-wide-report"
      show={show}
      size="lg">
      <ModalContent ref={modalContainerRef}>
        <div className="header" style={{ height: "60px" }}>
          <h4 className="modal-title">AI Suggestions</h4>
          <span
            className={`modal-close-btn ${theme != "light" ? "dark" : ""}`}
            onClick={closeModal}>
            <IoIosCloseCircle
              fontSize="45px"
              fill={theme !== "light" ? "#dfdcdc" : "#000"}
            />
          </span>
        </div>
        <div
          className="ai-result"
          style={{
            height: `${modalContainerDims.height - 110}px`,
            width: `${modalContainerDims.width - 50}px`,
          }}>
          <Markdown>{markdownContent}</Markdown>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default AiCommentaryView;

const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  .header {
    padding: 10px 25px;
    .modal-title {
      text-decoration: underline;
    }
    .modal-close-btn {
      cursor: pointer;
      position: absolute;
      top: -23px;
      right: -23px;
      background: #ffffff;
      width: auto;
      border-radius: 25px;
      &.dark {
        background: #333333;
      }
    }
  }
  .ai-result {
    transform: translate(25px, 25px);
    position: fixed;
    overflow: scroll;
    font-size: 20px;
  }
`;
