import styled from "styled-components";
import InlineDatepicker from "../inlineDatepicker";

const DatePickerSection = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
}) => {
  return (
    <Wrapper>
      <InlineDatepicker
        pickType="start"
        currDate={startDate}
        setDate={setStartDate}
        hour={startHour}
        setHour={setStartHour}
        minute={startMinute}
        setMinute={setStartMinute}
      />
      <InlineDatepicker
        pickType="end"
        currDate={endDate}
        setDate={setEndDate}
        hour={endHour}
        setHour={setEndHour}
        minute={endMinute}
        setMinute={setEndMinute}
      />
    </Wrapper>
  );
};

export default DatePickerSection;

const Wrapper = styled.div`
  display: inline-flex;
  width: calc(100%);
  > div{
    &:first-child{
      margin-right: 15px;
    }
  }
`;
