import ReactDatePicker from "react-datepicker";
import styled from "styled-components";

interface Props {
  selectedDate: { year: number; month: number; day: number };
  onYearChange: (date: Date) => void;
}

const YearPickView = ({ selectedDate, onYearChange }: Props) => {
  return (
    <Wrapper>
      <ReactDatePicker
        inline
        selected={
          new Date(selectedDate.year, selectedDate.month, selectedDate.day)
        }
        showYearPicker
        fixedHeight
        calendarClassName="date-default-view"
        onChange={onYearChange}
      />
    </Wrapper>
  );
};

export default YearPickView;

const Wrapper = styled.div`
  > div {
    > .date-default-view {
      border: 1px solid ${({ theme }) => theme.border_color};
      border-radius: 11px;
      width: 275px;
      background-color: ${({ theme }) => theme.widget.background};
      .react-datepicker__navigation {
        font-size: 18px;
        .react-datepicker__navigation-icon {
          &::before {
            border-color: ${({ theme }) => theme.text};
            border-style: solid;
            border-width: 3px 3px 0 0;
            border-radius: 2px;
            content: "";
            display: block;
            height: 13px;
            position: absolute;
            top: 18px;
            width: 13px;
          }
        }
      }
      .react-datepicker__year--container {
        .react-datepicker__header {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          padding: 15px 10px;
          color: ${({ theme }) => theme.text};
          background-color: ${({ theme }) => theme.widget.background};
          border-bottom: none;
        }
        .react-datepicker__year {
          height: 220px;
          .react-datepicker__year-wrapper {
            width: 100%;
            max-width: 100%;
            justify-content: space-evenly;
            .react-datepicker__year-text {
              padding: 7px;
              margin: 5px;
              height: 45px;
              font-size: 16px;
              color: ${({ theme }) => theme.text};
              background-color: ${({ theme }) => theme.body};
              border: ${({ theme }) => theme.widget.background};
              border-radius: 5px;
              &.react-datepicker__year-text--selected {
                color: ${({ theme }) => theme.text};
                background-color: #3aa98d;
                border: 1px solid #3aa98d;
              }
            }
          }
        }
      }
    }
  }
`;
