import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../state/store";
import { serializeFilterParams } from "../utils";

export const useFilterNavigate = () => {
  const navigateTo = useNavigate();
  const { dates: dateFilters } = useSelector(
    (state: RootState) => state.filters
  );

  let queryParams = useMemo(
    () => serializeFilterParams({ dateFilters }),
    [dateFilters]
  );

  const navigateWithFilters = (route: string) => {
    navigateTo(route + queryParams);
  };
  return navigateWithFilters;
};
