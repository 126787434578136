import { useEffect, useMemo, useRef, useState } from "react";
import { BSDashboardContext } from "../../contexts/bsDashboardContext";
import { useFetchBSWidgets } from "../../hooks/useFetchBSWidgets";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { format, parse } from "date-fns";

const BSDashboardCore = ({ children }) => {
  const [widgetsList, setWidgetsList] = useState([]);
  const [widgetsForPrint, setWidgetsForPrint] = useState<any>({});
  const userName = useSelector(
    (state: RootState) => state.company?.company_details?.user_name ?? ""
  );
  const printFormRef = useRef<HTMLFormElement>();
  const disablePrint = useMemo(() => {
    return !(
      widgetsList.length > 0 &&
      Object.keys(widgetsForPrint).length === widgetsList.length
    );
  }, [JSON.stringify(widgetsForPrint), JSON.stringify(widgetsList)]);

  const { data, error, isLoading } = useFetchBSWidgets();

  const filters = useSelector((state: RootState) => state.filters);

  const currentDateTime = new Date();
  const dateRange = filters.dates;
  const timeData = useMemo(() => {
    const currDate = format(currentDateTime, "yyyy-MM-dd");
    const currTime = currentDateTime.toLocaleTimeString();
    let text = " ";
    let startTime = " 00:00:00 ";
    let endTime = " 23:59:59 ";
    if (dateRange[1] === currDate) {
      endTime = " " + currTime;
    }
    text =
      ` ${format(
        parse(dateRange[0], "yyyy-MM-dd", currentDateTime),
        "do MMMM yyyy"
      )}` +
      startTime +
      " - " +
      ` ${format(
        parse(dateRange[1], "yyyy-MM-dd", currentDateTime),
        "do MMMM yyyy"
      )}` +
      endTime;
    return {
      text: text,
      currentTime: currTime,
    };
  }, [dateRange]);

  useEffect(() => {
    if (data) {
      setWidgetsList(data);
    }
  }, [data]);

  useEffect(() => {
    setWidgetsForPrint({});
  }, [JSON.stringify(filters)]);

  const addWidgetDataForPrint = (widgetData) => {
    setWidgetsForPrint((w) => ({ ...w, ...widgetData }));
  };

  const submitFormForPrint = () => {
    printFormRef.current?.submit();
  };

  return (
    <BSDashboardContext.Provider
      value={{
        widgetsList,
        widgetsForPrint,
        addWidgetDataForPrint,
        submitFormForPrint,
        handlePrint: submitFormForPrint,
        disablePrint,
        timeData,
        dataFetchError: error,
        isDataLoading: !data && isLoading,
        userName,
        currentDateTime,
      }}>
      {children}
      <form
        ref={printFormRef}
        action={`/api/v1/dar/business-summary/download/html`}
        target={`_blank`}
        method="post"
        className="print">
        <input
          name="business_summary_data"
          type="hidden"
          value={JSON.stringify(widgetsForPrint)}
        />
        <input name="date_range" type="hidden" value={dateRange} />
        <input
          name="current_date_time"
          type="hidden"
          value={format(currentDateTime, "do MMMM yyyy - HH:mm:ss")}
        />
        <input
          name="jwt"
          type="hidden"
          value={localStorage.getItem("jwt") ?? ""}
        />
      </form>
    </BSDashboardContext.Provider>
  );
};

export default BSDashboardCore;
