import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import useSWR from "swr";

interface Params {
  /** refresh interval in milliseconds */
  refreshInterval?: number | undefined;
}

const useFetchHappeningsData = ({ refreshInterval = undefined }: Params) => {
  const locationFilters = useSelector((state: RootState) => state.filters.locations);
  const { data, error, isLoading, mutate } = useSWR(
    `/api/v1/happenings?locations=${locationFilters.join(",")}`,
    { refreshInterval }
  );

  return { data, error, isLoading, mutate };
};

export { useFetchHappeningsData };
