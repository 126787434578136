import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../state/store";
import {
  endOfMonth,
  format,
  setHours,
  setMinutes,
  setSeconds,
  startOfMonth,
  subDays,
  subHours,
  subMonths,
} from "date-fns";
import { timezoneOffsetToSeconds } from "../../helpers/utils";

const DatePresetSection = ({ applyFilters }) => {
  const { dates: selectedDate } = useSelector(
    (state: RootState) => state.filters
  );
  const { company } = useSelector((state: RootState) => state);

  const getClassName = useCallback(
    (presetDates) => {
      return `preset ${
        presetDates[0] === selectedDate[0] && presetDates[1] === selectedDate[1]
          ? "selected"
          : ""
      }`;
    },
    [selectedDate]
  );

  const selectPresetDate = (presetDates, presetTime) => {
    applyFilters(presetDates, presetTime);
  };

  const dateRangePresets = useMemo(() => {
    let businessEndSecs: number =
      company?.company_details?.business_end_time || 0;
    let offset = company?.company_details?.time_zone_offset || "+00:00";
    let offsetS = timezoneOffsetToSeconds(offset);
    businessEndSecs = businessEndSecs + offsetS;
    let businessEndHour = Math.floor(businessEndSecs / 3600);
    let businessEndMinute = Math.floor((businessEndSecs % 3600) / 60);
    let todayBet = setHours(
      setMinutes(setSeconds(new Date(), 0), businessEndMinute),
      businessEndHour
    );
    let yesterdayBet = setHours(
      setMinutes(subDays(new Date(), 1), businessEndMinute),
      businessEndHour
    );
    let prevMonthFirstDayBet = setMinutes(
      setHours(startOfMonth(subMonths(new Date(), 1)), businessEndHour),
      businessEndMinute
    );
    let prevMonthLastDayBet = setMinutes(
      setHours(endOfMonth(subMonths(new Date(), 1)), businessEndHour),
      businessEndMinute
    );
    let presets = [
      {
        title: "Today",
        selected_dates: [
          format(subHours(todayBet, 24), "yyyy-MM-dd"),
          format(todayBet, "yyyy-MM-dd"),
        ],
        selected_time_range: [
          [businessEndHour, businessEndMinute],
          [businessEndHour, businessEndMinute],
        ],
      },
      {
        title: "Yesterday",
        selected_dates: [
          format(subHours(yesterdayBet, 24), "yyyy-MM-dd"),
          format(yesterdayBet, "yyyy-MM-dd"),
        ],
        selected_time_range: [
          [businessEndHour, businessEndMinute],
          [businessEndHour, businessEndMinute],
        ],
      },
      {
        title: "Last Month",
        selected_dates: [
          format(subHours(prevMonthFirstDayBet, 24), "yyyy-MM-dd"),
          format(prevMonthLastDayBet, "yyyy-MM-dd"),
        ],
        selected_time_range: [
          [businessEndHour, businessEndMinute],
          [businessEndHour, businessEndMinute],
        ],
      },
    ];
    return presets;
  }, [company]);

  return (
    <Wrapper>
      <span className="heading">Quick Selection: </span>
      {dateRangePresets.map((d, i) => (
        <span
          key={i}
          className={getClassName(d.selected_dates)}
          onClick={() => selectPresetDate(d.selected_dates, d.selected_time_range)}>
          {d.title}
        </span>
      ))}
    </Wrapper>
  );
};

export default DatePresetSection;

const Wrapper = styled.div`
  .heading {
    font-size: 16px;
    font-weight: bold;
  }
  .preset {
    font-size: 16px;
    font-weight: 400;
    margin: 0 10px;
    cursor: pointer;
    user-select: none;
    &:hover {
      text-decoration: underline;
    }
    &.selected {
      font-weight: 700;
      color: #14785f;
      pointer-events: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
  padding-bottom: 11px;
  border-bottom: 1px solid ${({ theme }) => theme.border_color};
`;
