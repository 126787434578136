import { addDays, format, subDays } from "date-fns";
import { Form } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import styled from "styled-components";

const TimePickView = ({
  selectedDate,
  onDateChange,
  hour,
  minute,
  onHourChange,
  onMinuteChange,
}) => {
  const increaseDate = () => {
    let updatedDate = addDays(selectedDate, 1);
    onDateChange(updatedDate);
  };

  const decreaseDate = () => {
    let updatedDate = subDays(selectedDate, 1);
    onDateChange(updatedDate);
  };

  const handlePickHour = (e) => {
    onHourChange(e.target.value);
  };

  const handlePickMinute = (e) => {
    onMinuteChange(e.target.value);
  };

  return (
    <Wrapper>
      <div className="selected-date-header">
        <span className="nav-btn prev-date-action" onClick={decreaseDate}>
          <FaChevronLeft fontSize={"20px"} />
        </span>
        <span className="selected-date">
          {format(selectedDate, "dd MMMM yyyy")}
        </span>
        <span className="nav-btn next-date-action" onClick={increaseDate}>
          <FaChevronRight fontSize={"20px"} />
        </span>
      </div>
      <div className="time-select-control">
        <div className="select-control-container hour-select">
          <Form.Select
            aria-label="Hour Select"
            name="hour"
            defaultValue={hour}
            onChange={handlePickHour}>
            {Array.from({ length: 24 }, (_, i) => (
              <option key={i} value={i}>
                {i.toString().padStart(2, "0")}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="colon">:</div>
        <div className="select-control-container minute-select">
          <Form.Select
            aria-label="Minute Select"
            name="minute"
            defaultValue={minute}
            onChange={handlePickMinute}>
            <option value={0}>00</option>
            <option value={30}>30</option>
          </Form.Select>
        </div>
      </div>
    </Wrapper>
  );
};

export default TimePickView;

const Wrapper = styled.div`
  height: 290px;
  padding: 20px;
  .selected-date-header {
    display: flex;
    justify-content: space-between;
    .selected-date {
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      user-select: none;
    }
    .nav-btn {
      cursor: pointer;
      user-select: none;
    }
  }
  .time-select-control {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    top: 110px;
    .colon {
      font-size: 26px;
      font-weight: bolder;
    }
    .select-control-container {
      height: 49px;
      select[name="hour"],
      select[name="minute"] {
        height: 100%;
        font-size: 1.2rem;
      }
    }
  }
`;
