import {
  addWeeks,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  differenceInYears,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  getWeekOfMonth,
  lastDayOfMonth,
  parse,
  parseISO,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from "date-fns";
import { COMPARISON_MODE, COMPARISON_OPTION } from "../../types/comparison";

type DateFormatString = "yyyy-MM-dd" | "dd-MM-yyyy" | "MM-dd-yyyy";

/**
 * Creates a function for formatting dates with provided string format
 * @returns function to format Date to string
 * */
export const createDateFormatter =
  (formatString: DateFormatString) => (date: Date) =>
    format(date, formatString);

/**
 * Creates a function for parsing dates with provided string format
 * @returns function to format Date string to Date
 * */
export const createDateParser =
  (formatString: DateFormatString) => (dateString: string) =>
    parse(dateString, formatString, new Date());

export const timeParser = (formatString: DateFormatString) => {
  return format(parseISO(formatString.split("+")[0]), "hh:mm a");
};

const getComparisonMode = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) => {
  let formatDate = createDateFormatter("dd-MM-yyyy");

  let diffYears = differenceInYears(startDate, endDate);
  let startDateOfYear = formatDate(startOfYear(startDate));
  let endDateOfYear = formatDate(endOfYear(startDate));
  let isSelectedRangeYear =
    startDateOfYear === formatDate(startDate) &&
    endDateOfYear === formatDate(endDate);

  let diffQuarters = differenceInQuarters(endDate, startDate);
  let startDateOfQuarter = formatDate(startOfQuarter(startDate));
  let endDateOfQuarter = formatDate(endOfQuarter(endDate));
  let isSelectedRangeQuarter =
    startDateOfQuarter === formatDate(startDate) &&
    endDateOfQuarter === formatDate(endDate);

  let diffMonths = differenceInMonths(endDate, startDate);
  let startDateOfMonth = formatDate(startOfMonth(startDate));
  let endDateOfMonth = formatDate(endOfMonth(startDate));
  let isSelectedRangeMonth =
    startDateOfMonth === formatDate(startDate) &&
    endDateOfMonth === formatDate(endDate);

  let diffWeeks = differenceInWeeks(endDate, startDate);
  let startDateOfWeek = formatDate(startOfWeek(startDate));
  let endDateOfWeek = formatDate(endOfWeek(startDate));
  let isSelectedRangeWeek =
    startDateOfWeek === formatDate(startDate) &&
    endDateOfWeek === formatDate(endDate);

  if (isSelectedRangeYear && diffYears === 0) {
    return "year";
  } else if (isSelectedRangeQuarter && diffQuarters === 0) {
    return "quarter";
  } else if (isSelectedRangeMonth && diffMonths === 0) {
    return "month";
  } else if (isSelectedRangeWeek && diffWeeks === 0) {
    return "week";
  } else if (formatDate(startDate) === formatDate(endDate)) {
    return "day";
  } else {
    return null;
  }
};

interface Params {
  baseDateRange: {
    startDate: Date;
    endDate: Date;
  };
  mode: COMPARISON_MODE;
  option: string;
  config: any;
}

const buildCompareDateRangeFromOption = ({
  baseDateRange,
  mode,
  option,
  config,
}: Params) => {
  const formatDate = createDateFormatter("yyyy-MM-dd");

  switch (mode) {
    case COMPARISON_MODE.YEAR:
      switch (option) {
        case COMPARISON_OPTION.SAME_RANGE_PREVIOUS:
          let previousYearRange = [
            formatDate(subYears(baseDateRange.startDate, 1)),
            formatDate(subYears(baseDateRange.endDate, 1)),
          ];
          return previousYearRange;
        // sameRangeLastRangeType not available for 'year' comparison mode
        case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE:
          let { count } = config;
          /** creates 2D array of n years date ranges `[[startDate, endDate]]` */
          let LastNYears = Array(count)
            .fill([])
            .map((d, i) => [
              formatDate(subYears(baseDateRange.startDate, ++i)),
              formatDate(subYears(baseDateRange.endDate, i)),
            ]);
          /** pick only the first and last date of the n years
           * `[startDateOfNYears, endDateOfNYears]` */
          let lastNYearsRange = [LastNYears[count - 1][0], LastNYears[0][1]];
          return lastNYearsRange;
        case COMPARISON_OPTION.CUSTOM:
          let { customDate } = config;
          let customRange = [
            formatDate(startOfYear(customDate)),
            formatDate(endOfYear(customDate)),
          ];
          return customRange;
        default:
          return ["", ""];
      }

    case COMPARISON_MODE.QUARTER:
      switch (option) {
        case COMPARISON_OPTION.SAME_RANGE_PREVIOUS:
          let previousQuarterRange = [
            formatDate(subQuarters(baseDateRange.startDate, 1)),
            formatDate(subQuarters(baseDateRange.endDate, 1)),
          ];
          return previousQuarterRange;
        case COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE:
          // Same quarter last year
          let sameQuarterLastYear = [
            formatDate(subYears(baseDateRange.startDate, 1)),
            formatDate(subYears(baseDateRange.endDate, 1)),
          ];
          return sameQuarterLastYear;
        case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE:
          let { count } = config;
          let lastNQuarters = Array(count)
            .fill([])
            .map((d, i) => [
              formatDate(subQuarters(baseDateRange.startDate, ++i)),
              formatDate(subQuarters(baseDateRange.endDate, i)),
            ]);
          let lastNQuartersRange = [
            lastNQuarters[count - 1][0],
            lastNQuarters[0][1],
          ];
          return lastNQuartersRange;
        case COMPARISON_OPTION.CUSTOM:
          let { customDate } = config;
          let customRange = [
            formatDate(startOfQuarter(customDate)),
            formatDate(endOfQuarter(customDate)),
          ];
          return customRange;
        default:
          return ["", ""];
      }

    case COMPARISON_MODE.MONTH:
      switch (option) {
        case COMPARISON_OPTION.SAME_RANGE_PREVIOUS:
          let previousMonthRange = [
            formatDate(subMonths(baseDateRange.startDate, 1)),
            formatDate(subMonths(baseDateRange.endDate, 1)),
          ];
          return previousMonthRange;
        case COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE:
          // Same month last year
          let sameMonthLastYearRange = [
            formatDate(subYears(baseDateRange.startDate, 1)),
            formatDate(lastDayOfMonth(subYears(baseDateRange.endDate, 1))),
          ];
          return sameMonthLastYearRange;
        case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE:
          let { count } = config;
          let lastNMonths = Array(count)
            .fill([])
            .map((d, i) => [
              formatDate(subMonths(baseDateRange.startDate, ++i)),
              formatDate(lastDayOfMonth(subMonths(baseDateRange.startDate, i))),
            ]);
          let lastNMonthsRange = [lastNMonths[count - 1][0], lastNMonths[0][1]];
          return lastNMonthsRange;
        case COMPARISON_OPTION.CUSTOM:
          // TODO - Recheck again
          let { customDate } = config;
          let customRange = [
            formatDate(customDate),
            formatDate(endOfMonth(customDate)),
          ];
          return customRange;
        default:
          return ["", ""];
      }

    case COMPARISON_MODE.WEEK:
      switch (option) {
        case COMPARISON_OPTION.SAME_RANGE_PREVIOUS:
          let previousWeekRange = [
            formatDate(subDays(baseDateRange.startDate, 7)),
            formatDate(subDays(baseDateRange.endDate, 7)),
          ];
          return previousWeekRange;
        case COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE:
          // Same week last month only
          let weekOfMonth = getWeekOfMonth(baseDateRange.startDate);
          let previousMonthNumber = baseDateRange.endDate.getMonth() - 1;
          let previousMonthYear = baseDateRange.endDate.getFullYear();
          let previousMonthDate = new Date(
            previousMonthYear,
            previousMonthNumber,
            1
          );
          let previousMonthWeekRange = [
            formatDate(
              startOfWeek(addWeeks(previousMonthDate, weekOfMonth - 1))
            ),
            formatDate(endOfWeek(addWeeks(previousMonthDate, weekOfMonth - 1))),
          ];
          return previousMonthWeekRange;
        case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE:
          let { count } = config;
          let lastNWeeks = Array(count)
            .fill([])
            .map((d, i) => [
              formatDate(subWeeks(baseDateRange.startDate, ++i)),
              formatDate(subWeeks(baseDateRange.endDate, i)),
            ]);
          let lastNWeeksRange = [lastNWeeks[count - 1][0], lastNWeeks[0][1]];
          return lastNWeeksRange;
        case COMPARISON_OPTION.CUSTOM:
          // TODO
          return ["", ""];
        default:
          break;
      }

    case COMPARISON_MODE.DAY:
      switch (option) {
        case COMPARISON_OPTION.SAME_RANGE_PREVIOUS:
          let previousDayRange = [
            formatDate(subDays(baseDateRange.endDate, 1)),
            formatDate(subDays(baseDateRange.endDate, 1)),
          ];
          return previousDayRange;
        case COMPARISON_OPTION.SAME_RANGE_LAST_RANGE_TYPE:
          let { subRange } = config;
          let sameDayLastSubRange = "";
          switch (subRange) {
            case "week":
              // Same day last week
              sameDayLastSubRange = formatDate(
                subDays(baseDateRange.endDate, 7)
              );
              break;
            case "month":
              // Same day last month
              sameDayLastSubRange = formatDate(
                subMonths(baseDateRange.endDate, 1)
              );
              break;
            case "year":
              // Same day last year
              sameDayLastSubRange = formatDate(
                subYears(baseDateRange.endDate, 1)
              );
              break;
            default:
              // Same day last week
              sameDayLastSubRange = formatDate(
                subDays(baseDateRange.endDate, 7)
              );
              break;
          }
          return [sameDayLastSubRange, sameDayLastSubRange];
        case COMPARISON_OPTION.SAME_RANGE_LAST_N_RANGE:
          let { count } = config;
          let lastNDays = Array(count)
            .fill([])
            .map((d, i) => [
              formatDate(subDays(baseDateRange.startDate, ++i)),
              formatDate(subDays(baseDateRange.endDate, i)),
            ]);
          return [lastNDays[count - 1][0], lastNDays[0][0]];
        case COMPARISON_OPTION.CUSTOM:
          let { selectedDate } = config;
          let customRange = [
            formatDate(selectedDate),
            formatDate(selectedDate),
          ];
          return customRange;
      }
  }
  return ["", ""];
};

const filterRowData = (data: any[], accessorKey: string) => {
  let rowData: any[] = [];
  if (data.length) {
    rowData = data.map((d) => {
      return d[accessorKey];
    });
  }
  return rowData;
};

export { getComparisonMode, buildCompareDateRangeFromOption, filterRowData };
