import { useContext } from "react";
import { useEffect, useState } from "react";
import { useElementSize } from "usehooks-ts";
import ToggleSwitch from "../../toggleSwitch";
import { WidgetContext } from "../../widgetContext";
import MagicQuadrantChart from "./magicQuadrantChart";
import Widget from "../../baseWidget";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const LabelToggle = ({ label, checked, handleToggle }) => {
  return (
    <LabelToggleWrapper className="toggle">
      <Form>
        <Form.Check
          type="switch"
          checked={checked}
          onChange={(e) => handleToggle(e.target.checked)}
        />
        <div className="label">{label}</div>
      </Form>
    </LabelToggleWrapper>
  );
};

const LabelToggleWrapper = styled.span`
  .form-switch {
    height: 25px;
    .form-check-input {
      height: 18px;
      width: 40px;
      &:checked {
        background-color: rgb(13, 138, 106);
        border-color: rgb(13, 138, 106);
      }
      &:focus {
        border-color: none;
      }
    }
  }
  .label {
    font-size: 12px;
    transform: translate(3px, 0px);
  }
`

const MagicQuadrantWidget = () => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [chartContainerRef, chartDimensions] = useElementSize();
  const [showLabels, setShowLabels] = useState(false);

  const widget = useContext(WidgetContext);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
    }
  }, [widget.widgetData]);

  return (
    <Widget ref={chartContainerRef}>
      <Widget.Header>
        <Widget.Title>{widgetData?.title}</Widget.Title>
        <Widget.Controls>
          {widgetData.widget_data?.data.series.length > 0 ? (
            <>
              <LabelToggle
                checked={showLabels}
                handleToggle={setShowLabels}
                label={"Labels"}
              />
              <Widget.AiSuggestionCta />
            </>
          ) : null}
        </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {widget?.widgetInputRequired ? (
          <div
            style={{
              height: chartDimensions.height - 90,
              width: chartDimensions.width - 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            Select a Menu Category to view insights
          </div>
        ) : (
          <>
            {widgetData.widget_data?.data.series.length > 0 ? (
              <MagicQuadrantChart
                data={widget.widgetData.widget_data}
                height={chartDimensions.height - 90}
                width={chartDimensions.width - 50}
                showLabels={showLabels}
              />
            ) : (
              <Widget.EmptyData
                height={chartDimensions.height - 120}
                width={chartDimensions.width - 50}
              />
            )}
          </>
        )}
      </Widget.Content>
    </Widget>
  );
};

export default MagicQuadrantWidget;
