import { useEffect, useMemo, useState } from "react";
import Widget from "../../baseWidget";
import { useElementSize } from "usehooks-ts";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";
import { useDispatch, useSelector } from "react-redux";
import StackedBarChart from "./stackedBarChart";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import { getFilterOptionsFromCategories } from "../stackedBarChartWidget/utils";
import ValueSelectDropdown from "../../valueSelectDropdown";
import { RootState } from "../../../state/store";

const StackedBarChartSwitchWidget = () => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [widgetTitle, setWidgetTitle] = useState("");
  const [containerRef, contentDimensions] = useElementSize();
  const [selectedOption, setSelectedOption] = useState(null);

  const widget = useWidgetContext();
  const dispatch = useDispatch();

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  const showKpiSelectDropdown = useMemo(() => {
    if (widgetData && widgetData.length && widgetData.length > 0) {
      if (
        widgetData[0]?.data?.series?.length ||
        widgetData[1]?.data?.series?.length
      ) {
        return true;
      }
      return false;
    }
    return false;
  }, [widgetData]);

  const valueSelectOptions = useMemo(() => {
    let options = [];
    if (widget.widgetData?.widget_data) {
      options = widget.widgetData.widget_data.map((d) => {
        let key = d?.config?.name;
        let name = d?.config?.name;
        return { key, name };
      });
    }
    return options;
  }, [JSON.stringify(widget.widgetData)]);

  const visualData = useMemo(() => {
    let data = null;
    if (widgetData && selectedOption) {
      data = widgetData.find((d) => d.config.name === selectedOption?.key);
    }
    if (!data) return null;
    return { data };
  }, [widgetData, selectedOption]);

  const isWidgetEmptyData = useMemo(() => {
    if (visualData?.data) {
      let seriesData = visualData?.data?.data?.series ?? [];
      if (seriesData.length > 0) {
        return false;
      }
      return true;
    }
    return true;
  }, [visualData]);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetTitle(widget.widgetData.title);
      setWidgetData(widget.widgetData.widget_data);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (
        widget.widgetFilterConfig.enableFilters &&
        widget.widgetData.widget_data[0].data.series.length &&
        !widgetFilterExists
      ) {
        let filterConfig = {
          name: widget.widgetFilterConfig.filterTitle,
          filterKey: widget.widgetFilterConfig.widgetFilterKey,
          options: getFilterOptionsFromCategories(
            widget.widgetData.widget_data[0],
            widget?.widgetData?.widget_data[0]?.config?.filter_by
          ),
        };
        dispatch(addWidgetToFilterList(filterConfig));
      }
    }
  }, [widget.widgetData]);

  const dimensions = useMemo(() => {
    let chart = { width: 0, height: 0 };
    let hPadding = 50;
    let vPadding = 90;
    chart.width = contentDimensions.width - hPadding;
    chart.height = contentDimensions.height - vPadding;
    return { chart };
  }, [JSON.stringify(contentDimensions)]);

  const handleDropdownSelect = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (valueSelectOptions.length > 0 && !selectedOption) {
      setSelectedOption(valueSelectOptions[0]);
    }
  }, [valueSelectOptions.length]);

  return (
    <Widget ref={containerRef}>
      <Widget.Header>
        <Widget.Title>{widgetTitle}</Widget.Title>
        <Widget.Controls>
          {showKpiSelectDropdown ? (
            <>
              <Widget.ExtendedAReportCta />
              <ValueSelectDropdown
                options={valueSelectOptions}
                onChange={handleDropdownSelect}
                selectedOption={selectedOption}
              />
            </>
          ) : null}
        </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {!isWidgetEmptyData && visualData ? (
          <StackedBarChart
            data={visualData.data}
            height={dimensions.chart.height}
            width={dimensions.chart.width}
            limitBars={5}
          />
        ) : (
          <Widget.EmptyData
            height={dimensions.chart.height}
            width={dimensions.chart.width}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default StackedBarChartSwitchWidget;
