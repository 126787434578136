import { createContext, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import ComparisonContainer from "../../../containers/layout/comparison";
import { formatUrlWithComparisonParams } from "../../../state/features/comparison/operations";
import { RootState } from "../../../state/store";
import {
  COMPARISON_ACTION,
  COMPARISON_OPTION,
} from "../../../types/comparison";
import ActiveFilterSection from "../../activeFilterSection";
import { createDateParser } from "../../comparison/utils";
import { getComparisonMode } from "../utils";
import { initialState, comparisonReducer } from "./reducer";
import { useFetchComparisonReportData } from "../../../helpers/hooks/useFetchComparisonReportData";
import { useParams } from "react-router-dom";
import ComparisonLoaderModal from "../comparisonLoader/comparisonLoader";
import CustomModal from "../../customModal";
import OverviewHeader from "../../overviewHeader";

interface Context {
  selectOption: (params: { option: COMPARISON_OPTION; config?: any }) => void;
}

export const ComparisonContext = createContext<Context | undefined>(undefined);

export const ComparisonProvider = ({
  children,
  isComparisonEnabled,
  comparisonDimensions,
  dashboardName,
  analyticsName,
  onShowComparisonMarketingContent,
  onHideComparisonMarketingContent,
  showComparisonMarketingContent,
  comparisonMarketingContent,
  setComparisonMarketingContent,
}) => {
  const {
    dates: dateFilters,
    widgets: widgetFilters,
    locations: locationFilters,
  } = useSelector((state: RootState) => state.filters);
  const [startDate, endDate] = dateFilters;
  const [state, dispatch] = useReducer(comparisonReducer, initialState);

  const [appliedDimension, setAppliedDimension] = useState("");

  const [showComparisonResults, setShowComparisonResults] = useState(false);
  const [startComparisonMode, setStartComparisonMode] = useState(false);
  const [comparisonData, setComparisonData] = useState(null);

  const routeMatch = useParams();

  let parseDate = useMemo(() => createDateParser("yyyy-MM-dd"), []);

  const [startDataFetch, setStartDataFetch] = useState(false);

  const comparisonUrl = useMemo(
    () =>
      formatUrlWithComparisonParams({
        baseUrl: `/api/v1/dar/${routeMatch?.dashboard}/${routeMatch?.widget}/comparison`,
        mode: state.comparisonMode,
        comparisonTimeRange: state.compareDateRange,
        baseTimeRange: dateFilters,
        widgetFilters,
        locationFilters,
        dimensions: appliedDimension,
      }),
    [
      state.comparisonMode,
      state.compareDateRange,
      JSON.stringify(dateFilters),
      JSON.stringify(locationFilters),
      JSON.stringify(widgetFilters),
      JSON.stringify(appliedDimension),
    ]
  );

  const {
    data,
    isValidating: submitDataLoading,
    error,
    mutate,
  } = useFetchComparisonReportData(startDataFetch ? comparisonUrl : null, {
    onSuccess(data) {
      setComparisonData(data);
      setStartDataFetch(false);
      setShowComparisonResults(true);
    },
    onError(data) {
      const statusCode = data?.response?.status;
      if(statusCode === 423) {
        setComparisonMarketingContent(data.response.data);
        onShowComparisonMarketingContent(true);
      }
      setStartDataFetch(false);
    },
  });

  const handleStartDataFetch = () => {
    setStartDataFetch(true);
  };

  useEffect(() => {
    let mode = getComparisonMode({
      startDate: parseDate(startDate),
      endDate: parseDate(endDate),
    });
    dispatch({ type: COMPARISON_ACTION.SET_COMPARISON_MODE, payload: mode });
  }, [startDate, endDate]);

  useEffect(() => {
    if (locationFilters && startComparisonMode) {
      handleStartDataFetch();
    }
  }, [JSON.stringify(locationFilters), startComparisonMode]);

  useEffect(() => {
    if (widgetFilters && startComparisonMode) {
      handleStartDataFetch();
    }
  }, [JSON.stringify(widgetFilters), startComparisonMode]);

  useEffect(() => {
    if (startComparisonMode) {
      handleStartDataFetch();
    }
  }, [appliedDimension, startComparisonMode]);

  const selectOption = (optionData: {
    option: COMPARISON_OPTION;
    config?: any;
  }) => {
    dispatch({
      type: COMPARISON_ACTION.SET_COMPARISON_OPTION,
      payload: {
        ...optionData,
        baseDateRange: {
          startDate: parseDate(startDate),
          endDate: parseDate(endDate),
        },
      },
    });
  };

  const applyDimensionFilter = (option) => {
    setAppliedDimension(option);
  };

  const onComparisonApply = () => {
    handleStartDataFetch();
    setStartComparisonMode(true);
  };

  const onExitComparison = () => {
    mutate(null, { revalidate: false });
    setComparisonData(null);
    setShowComparisonResults(false);
    setAppliedDimension("");
    setStartComparisonMode(false);
  };

  const providerData = useMemo(
    () => ({
      ...state,
      selectOption,
      showComparisonResults,
      onComparisonApply,
      submitDataLoading,
      onExitComparison,
      applyDimensionFilter,
      appliedDimension,
      onShowComparisonMarketingContent,
      comparisonMarketingContent,
    }),
    [
      state,
      selectOption,
      showComparisonResults,
      onComparisonApply,
      submitDataLoading,
      onExitComparison,
      applyDimensionFilter,
      appliedDimension,
      onShowComparisonMarketingContent,
      comparisonMarketingContent,
    ]
  );

  return (
    <ComparisonContext.Provider value={providerData}>
      {comparisonMarketingContent ? (
        <CustomModal show={showComparisonMarketingContent} size="lg">
          <CustomModal.Content>
            <CustomModal.CloseButton
              onClick={onHideComparisonMarketingContent}
            />
            <iframe
              height="100%"
              width="100%"
              title="Comparison feature unavailable"
              allowtransparency="allowtransparency"
              src={comparisonMarketingContent?.marketing_html_url}
            />
          </CustomModal.Content>
        </CustomModal>
      ) : null}
      {comparisonData ? (
        <>
          <OverviewHeader
            dashboardTitle={dashboardName}
            analyticsDashboardTitle={analyticsName}
            isComparisonEnabled={isComparisonEnabled}
            comparisonDimensions={comparisonDimensions}
          />
          <ActiveFilterSection />
          {comparisonData && submitDataLoading ? (
            <ComparisonLoaderModal />
          ) : comparisonData ? (
            <ComparisonContainer comparisonData={comparisonData} />
          ) : null}
        </>
      ) : (
        <>{children}</>
      )}
    </ComparisonContext.Provider>
  );
};

