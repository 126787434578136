import { useEffect } from "react";
import usePusher from "../../helpers/hooks/usePusher";

const LocationPresenceEvents = ({ dashboardName, locationId }) => {
  const { pusherInstance } = usePusher();
  useEffect(() => {
    let presenceChannel = pusherInstance?.subscribe(
      `presence-${dashboardName}_dashboard-location-${locationId}`
    );

    // Pusher Subscription Events
    presenceChannel?.bind("pusher:subscription_error", () => {});

    return () => {
      presenceChannel?.unsubscribe();
    };
  }, []);
  return <></>;
};

const DashboardPresenceEvents = ({ dashboardName, locationIds }) => {
  return (
    <>
      {locationIds.map((locationId) => (
        <LocationPresenceEvents
          key={crypto.randomUUID()}
          locationId={locationId}
          dashboardName={dashboardName}
        />
      ))}
    </>
  );
};

export default DashboardPresenceEvents;
