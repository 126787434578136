import { useEffect, useState, useMemo, useRef } from "react";
import { useElementSize } from "usehooks-ts";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../state/store";
import {
  differenceInMonths,
  parse,
  startOfMonth,
  startOfYear,
  subMonths,
} from "date-fns";
import DormantSelection from "./dormantFilterSelection";
import Widget from "../../baseWidget";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";
import TabularList from "./listTable";
import {
  TbSortAscendingNumbers,
  TbSortDescendingNumbers,
} from "react-icons/tb";

const SortControl = ({ sortAscending, handleSortModeSelection }) => {
  return (
    <SortButton onClick={handleSortModeSelection}>
      {sortAscending ? (
        <TbSortAscendingNumbers />
      ) : (
        <TbSortDescendingNumbers width={"14px"} />
      )}
    </SortButton>
  );
};

const SortButton = styled.div`
  box-sizing: border-box;
  height: 35px;
  width: 35px;
  border: 2px solid #58585e;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 2px 8px;
  padding: 0px;
  align-items: center;
`;

const DormantChartWidget = () => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [widgetRef, contentDimensions] = useElementSize();
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const widget = useWidgetContext();
  const { filters } = useSelector((state: RootState) => state);

  const [sortAscending, setSortAscending] = useState(false);
  const [rowLimit, setRowLimit] = useState(50);

  const selectedOptionRef = useRef<any>(null);

  const dormantEndDate = useMemo(
    () => parse(filters.dates[0], "yyyy-MM-dd", new Date()),
    [filters.dates[0]]
  );

  const dormantMonthOptions = [
    {
      name: "in the past 3 Months",
      value: differenceInMonths(
        dormantEndDate,
        startOfMonth(subMonths(dormantEndDate, 3))
      ),
    },
    {
      name: "in the past 6 Months",
      value: differenceInMonths(
        dormantEndDate,
        startOfMonth(subMonths(dormantEndDate, 6))
      ),
    },
    {
      name: "in the past 9 Months",
      value: differenceInMonths(
        dormantEndDate,
        startOfMonth(subMonths(dormantEndDate, 9))
      ),
    },
    {
      name: "in the past 12 Months",
      value: differenceInMonths(
        dormantEndDate,
        startOfYear(subMonths(dormantEndDate, 12))
      ),
    },
  ];

  const tableData = useMemo(() => {
    if (widget.widgetData.widget_data) {
      return {
        ...widget.widgetData.widget_data,
        data: {
          ...widget.widgetData.widget_data.data,
          rows: widget.widgetData.widget_data.data.rows
            .sort((a, b) =>
              sortAscending
                ? a.last_order_age - b.last_order_age
                : b.last_order_age - a.last_order_age
            )
            .filter((d, i) => i < rowLimit),
        },
      };
    }
    return null;
  }, [sortAscending, rowLimit, widget.widgetData]);

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(widget.widgetData);
      if (selectedOptionRef.current) {
        setSelectedOption(selectedOptionRef.current);
      }
    }
  }, [widget.widgetData]);

  const tableRowData = useMemo(() => {
    if (widgetData?.widget_data) {
      return widgetData.widget_data.data.rows;
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  const handleDormantDaysSelection = (months) => {
    widget.getWidgetDataWithFilters([{ key: "last_order_age", value: months }]);
    let selOption = dormantMonthOptions.find((d) => d.value === months) ?? null;
    selectedOptionRef.current = selOption;
    setSelectedOption(selOption);
  };

  const changeSortMode = () => {
    setSortAscending((s) => !s);
  };

  return (
    <Widget ref={widgetRef}>
      <Widget.Header>
        <Widget.Title>{widgetData?.title}</Widget.Title>
        <Widget.Controls>
          <Widget.ExtendedAReportCta
            additionalFilters={[
              {
                key: "last_order_age",
                value: selectedOption?.value ?? dormantMonthOptions[0].value,
              },
            ]}
          />
          <DormantSelection
            filterPretext={"Dormant Customers"}
            selectedOptionName={
              selectedOption?.name ?? dormantMonthOptions[0].name
            }
            dormantDaysOptions={dormantMonthOptions}
            endDate={dormantEndDate}
            handleDormantDaysSelection={handleDormantDaysSelection}
          />
          <SortControl
            sortAscending={sortAscending}
            handleSortModeSelection={changeSortMode}
          />
        </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {tableRowData.length > 0 ? (
          <TabularList
            data={tableData}
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 75}
          />
        ) : (
          <Widget.EmptyData
            width={contentDimensions.width - 50}
            height={contentDimensions.height - 75}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default DormantChartWidget;
