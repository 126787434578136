import { useEffect } from "react";
import usePusher from "../../helpers/hooks/usePusher";

const LocationLivePresenceEvents = ({ dashboardName, locationId }) => {
  const { pusherInstance } = usePusher();
  useEffect(() => {
    let livePresenceChannel = pusherInstance?.subscribe(
      `presence-live-${dashboardName}_dashboard-location-${locationId}`
    );

    // Pusher Subscription Events
    livePresenceChannel?.bind("pusher:subscription_error", () => {});

    return () => {
      livePresenceChannel?.unsubscribe();
    };
  }, []);
  return <></>;
};

const DashboardLivePresenceEvents = ({ dashboardName, locationIds }) => {
  return (
    <>
      {locationIds.map((locationId) => (
        <LocationLivePresenceEvents
          key={crypto.randomUUID()}
          locationId={locationId}
          dashboardName={dashboardName}
        />
      ))}
    </>
  );
};

export default DashboardLivePresenceEvents;
