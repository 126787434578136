import { Fragment, useState } from "react";
import type { ReactElement } from "react";
import styled from "styled-components";
import { BsBoxArrowUpRight } from "react-icons/bs";
import {
  TbSortDescendingNumbers,
  TbSortAscendingNumbers,
} from "react-icons/tb";
import WidgetTableModal from "./tableModal";
import { FaMagnifyingGlassArrowRight } from "react-icons/fa6";
import { format } from "date-fns";
import AiAdvisorCta from "../../features/ai/aiAdvisorCta";

interface IWidgetHeaderSectionProps {
  widgetTitle: string;
  enableSorting: boolean;
  enableFiltering: boolean;
  clickHandler: () => void;
  handleWidgetDataSorting?: (sortAscending: boolean) => void;
  handleWidgetDataFiltering?: (option: any) => void;
  additionalFilters?: ReactElement[];
  showWidgetReport?: boolean;
  widgetReportData?: { rows: any[]; columns: any[] };
  enableExtendedAReport?: boolean;
  handleExtendedAAction: () => any;
  isWidgetDLevel?: boolean;
  isTimelessWidget?: boolean;
  lastUpdatedTime?: Date;
  aiAdvisor: {
    isAiSuggestionEnabled: boolean;
    isAiSuggestionLoading: boolean;
    isAiSuggestionLoadError: boolean;
    getAiSuggestion: () => void;
  };
}

const widgetFilterPresets = [5, 10, 15, 20];

const WidgetHeaderSection = (props: IWidgetHeaderSectionProps) => {
  const [selectedFilterOption, setSelectedFilterOption] = useState("All");
  const [sortAscending, setSortAscending] = useState(false);

  const handleFilterOptionSelection = (e) => {
    let option = e.target.value;
    setSelectedFilterOption(option);
    props.handleWidgetDataFiltering!(option);
  };

  const handleSortModeSelection = () => {
    setSortAscending((mode) => {
      props.handleWidgetDataSorting!(!mode);
      return !mode;
    });
  };

  return (
    <Wrapper>
      <div className="header">
        <span className="title" onClick={props.clickHandler}>
          {props.widgetTitle}
        </span>
      </div>
      <div className="options">
        {props.enableExtendedAReport ? (
          <div
            className="extended-report-option"
            onClick={props.handleExtendedAAction}>
            <FaMagnifyingGlassArrowRight fontSize={"18px"} />
          </div>
        ) : null}
        {props.additionalFilters &&
          props.additionalFilters.length > 0 &&
          props.additionalFilters.map((elem, k) => (
            <Fragment key={k}>{elem}</Fragment>
          ))}
        {props.enableFiltering && (
          <select
            className="filter-select"
            onChange={handleFilterOptionSelection}>
            {widgetFilterPresets.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
            <option value="All">All</option>
          </select>
        )}
        {props.enableSorting && (
          <div className="sort-option" onClick={handleSortModeSelection}>
            {sortAscending ? (
              <TbSortAscendingNumbers />
            ) : (
              <TbSortDescendingNumbers width={"14px"} />
            )}
          </div>
        )}
        {props.aiAdvisor.isAiSuggestionEnabled ? (
          <AiAdvisorCta
            showError={props.aiAdvisor.isAiSuggestionLoadError}
            showLoadingIndicator={props.aiAdvisor.isAiSuggestionLoading}
            onClick={props.aiAdvisor.getAiSuggestion}
          />
        ) : null}
      </div>
    </Wrapper>
  );
};

export default WidgetHeaderSection;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .header {
    .title {
      text-decoration: underline;
      cursor: pointer;
    }
    .live-badge {
      margin-left: 10px;
      margin-bottom: 5px;
    }
    .popover-wrapper {
      margin-left: 10px;
    }
  }
  .options {
    display: flex;
    .filter-select {
      box-sizing: border-box;
      height: 30px;
      border-radius: 8px;
      font-size: 14px;
      border: 1px solid #bebebe;
      background-color: ${({ theme }) => theme.widget.background};
      color: ${({ theme }) => theme.text};
    }
    .sort-option,
    .extended-report-option {
      margin-left: 7px;
      box-sizing: border-box;
      height: 35px;
      width: 35px;
      cursor: pointer;
      color: ${({ theme }) => theme.button_plain.text};
      border: 1.5px solid ${({ theme }) => theme.button_plain.border};
      &:hover {
        color: ${({ theme }) => theme.button_plain.hover_text};
        background: ${({ theme }) => theme.button_plain.hover};
      }
      border-radius: 8px;
      display: flex;
      justify-content: center;
      padding: 2px 8px;
      padding: 0px;
      align-items: center;
    }
  }
  .dropdown {
    height: 30px;
    margin-left: 7px;
    .btn-primary {
      background-color: ${({ theme }) => theme.widget.background};
      border-color: ${({ theme }) => theme.widget.background};
      color: ${({ theme }) => theme.text};
      border-radius: 8px;
      &:focus {
        box-shadow: none;
      }
    }
    > .filter-toggle {
      height: 30px;
      background: #fcfcfc;
      border: 1px solid #bebebe;
      border-radius: 8px;
      vertical-align: top;
      font-size: 11px;
    }
  }
  .a-level-link {
    background: transparent;
    color: #0d6efd;
    border-color: transparent;
    box-shadow: none;
    &:hover,
    &:focus,
    &.selected {
      background: transparent;
      color: #0d6efd;
      border-color: trans;
    }
  }
`;
