import styled from "styled-components";
import { ReportData } from "../../../types/reports";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ImFileEmpty } from "react-icons/im";
import SelectSubReport from "./selectSubReport";
import ColumnFilterDropdown from "./columnFilterDropdown";
import { formatText } from "../../../helpers/utils";
import FtsFilterDropdown from "./ftsFilterDropdown";
import { formatDateWithTimezoneOffset, rearrangeColumns } from "./utils";

interface IReportTableContentProps {
  tableData: ReportData["report_data"];
  columnFilters: any[];
  applyColumnFilters: (filters: any) => void;
  isLoading: boolean;
  ftsFilterKeys: string[];
  columnFilterKeys: string[];
  setFtsFilters: (param: { key: string; query: string }) => any;
}

const TableComponent = ({
  tableData,
  isLoading,
  columnFilterKeys,
  ftsFilterKeys,
  columnFilters,
  applyColumnFilters,
  setFtsFilters,
}: IReportTableContentProps) => {
  const columnHelper = createColumnHelper();
  const [subReportType, setSubReportType] = useState<string | null>(null);
  const [subReportData, setSubReportData] = useState<any>({});

  const { rows, columns } = useMemo(() => {
    let rows: any[] = [];
    let columns: any[] = [];
    if (tableData.columns.length) {
      const tableColumns = rearrangeColumns(tableData.columns);
      columns = tableColumns.map((c) =>
        columnHelper.accessor(c.accessor, {
          header: c.header,
          cell: (info) => info.getValue(),
          meta: c,
        })
      );
    }
    if (tableData.rows.length) {
      rows = tableData.rows;
    }

    return { rows, columns };
  }, [tableData]);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleFilterApply = (columnName, filterKey, filterData) => {
    let existingFilters = columnFilters;
    let index = columnFilters.findIndex((d) => d.filterKey === filterKey);
    if (index !== -1) {
      let updatedFilter = columnFilters[index];
      updatedFilter["options"] = filterData;
      existingFilters[index] = updatedFilter;
      applyColumnFilters(existingFilters);
    } else {
      applyColumnFilters([
        ...columnFilters,
        { name: columnName, filterKey, options: filterData },
      ]);
    }
  };

  const handleFtsFilterApply = (inputText, key) => {
    setFtsFilters({ key, query: inputText });
  };

  const handlePrimaryCellClick = (cell) => {
    setSubReportType("OrderDetails");
    setSubReportData(cell);
  };

  const handleHideSubReport = () => {
    setSubReportType(null);
    setSubReportData({});
  };

  const formatCell = ({ cell }) => {
    let cellContent = cell.renderValue();
    let columnType = cell.column.columnDef.meta.type ?? "Text";
    let timezoneOffset = cell.row.original?.offset;
    if (columnType === "Date") {
      if (timezoneOffset) {
        cellContent = formatDateWithTimezoneOffset(
          cell.renderValue().toString(),
          timezoneOffset,
          "yyyy-MM-dd hh:mm:ss aa"
        );
      }
    }
    if (columnType === "Number") {
      cellContent = parseFloat(cell.renderValue()).toLocaleString();
    }
    return cellContent;
  };

  return (
    <TableContainer>
      {isLoading ? (
        <div className="table-loading-state">
          Loading <Spinner animation="border" />
        </div>
      ) : null}
      {rows.length || isLoading ? (
        <table className={`${isLoading ? "loading" : ""}`}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <th>
                  <span>#</span>
                </th>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {columnFilterKeys.includes(header.id) ? (
                      <ColumnFilterDropdown
                        columnFilterKey={header.id}
                        headerTitle={formatText(header.column.columnDef.header)}
                        appliedFilters={columnFilters}
                        setAppliedFilters={handleFilterApply}
                      />
                    ) : ftsFilterKeys.includes(header.id) ? (
                      <FtsFilterDropdown
                        headerTitle={formatText(header.column.columnDef.header)}
                        applyFtsFilters={handleFtsFilterApply}
                        ftsFilterKey={ftsFilterKeys.find(
                          (d) => d === header.id
                        )}
                      />
                    ) : (
                      <span>{formatText(header.column.columnDef.header)}</span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getCoreRowModel().rows.map((row, rowIndex) => (
              <tr key={row.id}>
                <td>
                  <span>{rowIndex + 1}</span>
                </td>
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={cell.id}
                    onClick={
                      cell?.column?.columnDef?.meta?.primary_key
                        ? () => handlePrimaryCellClick(cell)
                        : () => null
                    }
                    className={`${
                      cell?.column?.columnDef?.meta?.primary_key
                        ? "primary"
                        : ""
                    }`}>
                    {formatCell({
                      cell,
                    })}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <span className="no-reports">
          <ImFileEmpty />
          No Data Available
        </span>
      )}
      {subReportType ? (
        <SelectSubReport
          reportType={subReportType}
          cellData={{
            ...subReportData,
            hideSubReport: () => handleHideSubReport(),
          }}
        />
      ) : null}
    </TableContainer>
  );
};

export default TableComponent;

const TableContainer = styled.div`
  min-height: 600px;
  overflow-x: scroll;
  .table-loading-state {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 30% 30%;
    position: absolute;
    z-index: 1;
  }
  .no-reports {
    width: 550px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3.5rem;
    font-weight: 700;
    background-color: ${({ theme }) => theme.widget.background};
    gap: 0.5rem;
    margin: 2rem auto;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    svg {
      font-size: 40px;
    }
  }
  table {
    &.loading {
      filter: blur(8px);
      -webkit-filter: blur(8px);
      pointer-events: none;
    }
    width: 100%;
    border-spacing: 0;
    overflow-x: scroll;
    background-color: ${({ theme }) => theme.widget.background};
    > thead {
      height: 55px;
      border-bottom: 1px solid #e4e4e4;
      > tr {
        > th {
          margin-left: 20px;
          font-size: 16px;
          color: ${({ theme }) => theme.text};
          font-weight: 700;
          position: relative;
          white-space: nowrap;
          padding: 0 0.5rem;
          > p {
            display: flex;
            align-items: center;
            margin-bottom: 0;
          }
          > div {
            position: absolute;
            top: 100%;
            background-color: #ffffff;
            border-color: #929292;
            border-style: solid;
            border-width: 0 1px 1px;
            left: -1px;
            padding: 0.5rem;
            > ul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              > li {
                padding: 5px 0;
                label {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
      border-bottom: 1px solid #e4e4e4;
    }
    > tbody {
      > tr {
        > td {
          &.primary {
            color: #0094ff;
            font-weight: 700;
            cursor: pointer;
          }
          font-size: 15px;
          font-weight: 400;
          line-height: 21.48px;
          padding: 0 0.5rem;
          white-space: nowrap;
        }
        height: 49px;
        &:nth-child(odd) {
          background-color: ${({ theme }) => theme.body};
        }
      }
    }
  }
`;
