import { stringify } from "query-string";
import { rmsApi } from "../../axios";
import { AppDispatch, RootState } from "../../store";
import * as types from "./types";

export const formatUrlWithComparisonParams = ({
  baseUrl,
  comparisonTimeRange,
  mode,
  baseTimeRange,
  widgetFilters,
  locationFilters,
  dimensions,
}: any) => {
  let queryParams = "?";
  if (baseTimeRange[0] !== "") {
    if (queryParams.length > 1) {
      queryParams += "&";
    }
    queryParams += stringify(
      {
        base_time_range: `${baseTimeRange[0]}:${baseTimeRange[1]}`,
      },
      { encode: false }
    );
    queryParams += "&";
    queryParams += stringify(
      {
        compare_time_range: `${comparisonTimeRange[0]}:${comparisonTimeRange[1]}`,
      },
      { encode: false }
    );
  }
  if (widgetFilters.length > 0) {
    for (let i = 0; i < widgetFilters.length; i++) {
      if (queryParams.length > 1) {
        queryParams += "&";
      }
      let ids = widgetFilters[i].data.map((w) => w.id);
      queryParams += stringify(
        {
          [widgetFilters[i].key]: ids,
        },
        { arrayFormat: "comma" }
      );
    }
  }
  if (locationFilters.length > 0) {
    if (queryParams.length > 1) {
      queryParams += "&";
    }
    queryParams += stringify(
      { locations: locationFilters },
      { arrayFormat: "comma" }
    );
  }
  if (dimensions) {
    queryParams += "&";
    queryParams += `compare-dimension=${dimensions}`;
  }
  return `${baseUrl}/${mode}${queryParams}`;
};

const fetchComparisonData =
  ({ mode, comparisonTimeRange }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    new Promise((resolve, reject) => {
      dispatch({ type: types.FETCH_COMPARISON_DATA });
      rmsApi
        .get(
          formatUrlWithComparisonParams({
            baseUrl: "/api/v1/dar/sales/orders/comparison",
            comparisonTimeRange,
            mode,
            baseTimeRange: getState().filters.dates,
            widgetFilters: getState().filters.widgets,
          })
        )
        .then((response) => {
          dispatch({
            type: types.FETCH_COMPARISON_DATA_SUCCESS,
            payload: response,
          });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: types.FETCH_COMPARISON_DATA_FAIL, payload: error });
          reject(error);
        });
    });
  };

const exitComparisonMode =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    new Promise((resolve, reject) => {
      dispatch({ type: types.EXIT_COMPARISON_MODE });
    });
  };

export default { fetchComparisonData, exitComparisonMode };
